import { createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../../http'
import { INotification, INotificationType } from '../../user/user.types'

export interface GetNotificationResponse {
  unread: Array<INotification>
  read: Array<INotification>
  typeNotifications: Array<INotificationType>
}

export const getLastNotification = createAsyncThunk<GetNotificationResponse>(
  'notification/getLastNotification',
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get('/notification/getLastNotification')
      return data
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Не удалось получить информацию об уведомлениях!'
      )
    }
  }
)

export const getAllNotification = createAsyncThunk<GetNotificationResponse>(
  'notification/getAllNotification',
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get('notification/getAllNotification')
      return data
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Не удалось получить информацию об уведомлениях!'
      )
    }
  }
)

export interface ICheckNotification extends INotification {
  isShow: true
}

export const saveStatusNotification = createAsyncThunk<
  unknown,
  ICheckNotification
>('notification/saveStatusNotification', async (arg, thunkAPI) => {
  try {
    const { data } = await http.post('notification/saveStatusNotification', arg)
    return data
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось отметить уведомление!')
  }
})

export const saveStatusNotificationsList = createAsyncThunk<
  unknown,
  Array<ICheckNotification>
>('notification/saveStatusNotificationsList', async (arg, thunkAPI) => {
  try {
    const { data } = await http.post(
      'notification/saveStatusNotificationsList',
      arg
    )
    return data
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось отметить уведомления!')
  }
})

export const parseIOSDate = (date_: string): Date => {
    if (getBrowser().name !== 'Safari') return new Date(date_)
    
    return new Date(String(date_).replace(/\s+/g, 'T'))
}

function getBrowser() {
    let tem: any
    
    const userAgent: string = navigator.userAgent
    
    let M =
        userAgent.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
        ) || []
    
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(userAgent) || null
        return { name: 'IE', version: tem[1] || '' }
    }
    
    if (M[1] === 'Chrome') {
        tem = userAgent.match(/\bOPR|Edge\/(\d+)/)
        if (tem != null) {
            return { name: 'Opera', version: tem[1] }
        }
    }
    
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1])
    }
    
    return {
        name: M[0],
        version: M[1],
    }
}

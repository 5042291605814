import classNames from 'classnames';
import { getServerPath } from '../../http';
import styles from './Avatar.module.scss';
import { FC, ImgHTMLAttributes } from 'react';

export const Avatar: FC<ImgHTMLAttributes<HTMLImageElement>> = ({
    src,
    className,
    ...rest
}) => {
    return (
        <img
            className={classNames(styles.avatar, className)}
            src={`${getServerPath()}/${src}`}
            {...rest}
        />
    );
};

import { AttendantLessonStatus, AttendantMark, AttendantStudent, INearestLesson } from './attendant.types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../../http'

export interface getNearestDataLessonResponse {
    nearestLessons: Array<INearestLesson>;
    typeMarks: Array<AttendantMark>;
    typeStatuses: Array<AttendantLessonStatus>;
}

export const getNearestDataLesson =
    createAsyncThunk<getNearestDataLessonResponse | null>(
        'teacher/attendant/getNearestDataLesson',
        async (_: void, thunkAPI) => {
            try {
                const { data } = await http.get(
                    'performance/getNearestDataLesson',
                )
                
                return {
                    typeMarks: data.marks.map(el => ({
                        typeId: el.id,
                        type: el.name,
                    })),
                    typeStatuses: data.statuses.map(el => ({
                        statusId: el.id,
                        status: el.name,
                    })),
                    nearestLessons: data.lessons.map(lesson => ({
                        lessons: {
                            startDate: lesson.lessons.startedAt,
                            endDate: lesson.lessons.finishedAt,
                            subject: lesson.lessons.subject.name,
                            group: lesson.lessons.group.name,
                            groupId: lesson.lessons.group.id,
                            audienceName: lesson.lessons.audience.name,
                            active: lesson.lessons.active,
                            lessonId: lesson.lessons.id,
                        },
                        
                        academicDataLessonTeacher: lesson.material?.material?.id
                                                   ? {
                                methodPackageModel: {
                                    deadline: lesson.material.deadline,
                                    material: {
                                        id: lesson.material.material.id,
                                        createdDate:
                                        lesson.material.material
                                            .createdAt,
                                        theme: lesson.material.material.theme,
                                        name: lesson.material.material.name,
                                        source: lesson.material.material
                                            .source,
                                        pathFileMaterial: null,
                                        pathHomeworkFile: null,
                                        pathPracticeFile: null,
                                        pathPresentationFile: null,
                                    },
                                },
                                
                                academicStudentModels: lesson.attendance.map(
                                    ({ attendance, fullname }) => ({
                                        fio: fullname,
                                        presentStudent: {
                                            id: attendance.id,
                                            userId: attendance.userId,
                                            status: attendance.status,
                                            mark: attendance.mark,
                                            reward: attendance.reward,
                                            comment: attendance.comment,
                                            typeMark: attendance.typeMark,
                                            presentLessonId:
                                            attendance.presentLessonId,
                                            theme: null,
                                        },
                                    }),
                                ),
                            }
                                                   : undefined,
                    })),
                }
            } catch (e) {
                return thunkAPI.rejectWithValue(
                    'Не удалось получить информацию о ближайшем уроке!',
                )
            }
        },
    )

export type presentsAttendantsType = Array<AttendantStudent>;

export const savePresentUserData = createAsyncThunk<
    presentsAttendantsType,
    Omit<AttendantStudent['presentStudent'], 'presentLessonId' | 'theme'>
>('teacher/attendant/savePresentUserData', async (params, thunkAPI) => {
    try {
        const { data } = await http.post('performance/savePresentUserData', {
            id: params.id,
            userId: params.userId,
            status: params.status,
            mark: params.mark,
            reward: params.reward,
            comment: params.comment || '',
            typeMark: params.typeMark,
        })
        
        if (data?.message) return thunkAPI.rejectWithValue(data.message)
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(
            'Не удалось сохранить информацию о студенте!',
        )
    }
})

export const uploadMaterialAndHomework = createAsyncThunk<
    unknown,
    {
        materialId: number; // material`s id
        deadline?: number; // deadline as timestamp
        id: number; // present lesson`s id
    }
>('teacher/attendant/uploadMaterialAndHomework', async (params, thunkAPI) => {
    try {
        const { data } = await http.post(
            'performance/uploadMaterialAndHomework',
            params,
        )
        return data
    } catch (e) {
        return thunkAPI.rejectWithValue(
            'Не удалось прикрепить методический материал!',
        )
    }
})

import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { DataTable, UserTemplate } from 'src/components/DataTable'
import { EduPaths } from 'src/components/Routing/Routing.types'
import { NotificationTypes, notify } from 'src/utils/notify'
import { generatePass } from 'src/utils/password/password'
import { requireField } from 'src/constants/constants'
import React, { FC, useRef, useState } from 'react'
import { Modal } from 'src/components/Modal/Modal'
import { Dropdown } from 'src/components/Dropdown'
import { InputText } from 'primereact/inputtext'
import { Toolbar } from 'src/components/Toolbar'
import { Card } from 'src/components/Card/Card'
import { FormItem } from 'src/components/Form'
import { useGetGroupsQuery } from 'src/api'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import Loader from 'src/components/Loader'
import { Toast } from 'primereact/toast'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import { map } from 'lodash'
import {
    AddStudentAccountArg,
    StudentResult,
    UpdateStudentAccountArg,
    useAddStudentAccountMutation,
    useGetStudentsQuery,
    useUpdateStudentAccountMutation,
} from 'src/api/endpoints/students'

type StudentsInitialState = Partial<UpdateStudentAccountArg> &
    Partial<AddStudentAccountArg>;

type StudentsForm = UpdateStudentAccountArg & AddStudentAccountArg;

export const Students: FC = () => {
    const [modal, setModal] = useState<boolean>(false)
    const toast = useRef<Toast>(null)
    
    const { data, isLoading, isFetching } = useGetStudentsQuery()
    const [add, { isLoading: isAddLoading }] = useAddStudentAccountMutation()
    const [update, { isLoading: isUpdateLoading }] = useUpdateStudentAccountMutation()
    const [initialState, setInitialState] =
        useState<StudentsInitialState | null>(null)
    
    const onCreate = async (form: AddStudentAccountArg): Promise<void> => {
        await add(form).unwrap()
        
        notify(
            toast,
            {
                type: NotificationTypes.success,
                content: 'Вы успешно добавили студента!',
            },
            true,
        )
    }
    
    const onUpdate = async (form: UpdateStudentAccountArg): Promise<void> => {
        await update(form).unwrap()
        
        setModal(false)
        setInitialState(null)
        
        notify(
            toast,
            {
                type: NotificationTypes.success,
                content: 'Вы успешно обновили студента!',
            },
            true,
        )
    }
    
    return (
        <StudentLayout>
            <Helmet title={'Студенты'} />
            <Toast ref={toast} />
            <Modal
                header={`${initialState ? 'Обновление' : 'Добавление'} студента`}
                visible={modal}
                onHide={(): void => {
                    setInitialState(null)
                    setModal(false)
                }}
            >
                <ModalContent
                    initialState={initialState}
                    onSubmit={initialState ? onUpdate : onCreate}
                    update={!!initialState?.studentId}
                    loading={initialState ? isUpdateLoading : isAddLoading}
                />
            </Modal>
            <Card headerClassName={'mt-24'} header={'Студенты'}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <DataTable
                        header={
                            <Toolbar
                                add={{
                                    onClick: () => setModal(true),
                                    loading: isAddLoading,
                                }}
                            />
                        }
                        loading={isFetching}
                        value={data}
                        dataKey={'id'}
                    >
                        <Column
                            filter
                            sortable
                            field={'name'}
                            header="Имя"
                            body={rowData => (
                                <UserTemplate
                                    rowData={rowData}
                                    link={`${EduPaths.statistics}/${rowData.id}`}
                                />
                            )}
                        />
                        <Column
                            filter
                            sortable
                            field={'surname'}
                            header="Фамилия"
                        />
                        <Column
                            filter
                            sortable
                            field={'patronymic'}
                            header="Отчество"
                        />
                        <Column
                            filter
                            sortable
                            field={'group'}
                            header="Группа"
                        />
                        <Column
                            filter
                            sortable
                            field={'login'}
                            header="Логин"
                        />
                        <Column
                            filter
                            sortable
                            field={'phone'}
                            header="Телефон"
                        />
                        <Column
                            filter
                            sortable
                            field={'email'}
                            header="Почта"
                        />
                        <Column
                            filter
                            sortable
                            field={'address'}
                            header="Адрес"
                        />
                        <Column
                            body={(row: StudentResult) =>
                                <Button
                                    icon="pi pi-pencil"
                                    onClick={(): void => {
                                        setModal(true)
                                        setInitialState({
                                            studentId: row.id,
                                            curGroup: row.groupId,
                                            contract:
                                            row.contractNumber,
                                            password: undefined,
                                        })
                                    }}
                                />
                            }
                        />
                    </DataTable>
                )}
            </Card>
        </StudentLayout>
    )
}

interface ModalContentProps {
    onSubmit: (value: StudentsForm) => Promise<void>;
    initialState: StudentsInitialState | null;
    update: boolean;
    loading: boolean;
}

const ModalContent: FC<ModalContentProps> = ({
                                                 onSubmit,
                                                 initialState,
                                                 update,
                                                 loading,
                                             }) => {
    const formik = useFormik<StudentsForm>({
        initialValues: {
            login: '',
            password: '',
            curGroup: null,
            contract: '',
            studentId: null,
            ...initialState,
        },
        validate: ({ login, password, curGroup, contract }) => {
            const errors: Partial<Record<keyof StudentsForm, any>> = {}
            
            if (!update && !login) errors.login = requireField
            if (!update && !password) errors.password = requireField
            if (!curGroup) errors.curGroup = requireField
            if (!contract) errors.contract = requireField
            
            return errors
        },
        onSubmit: values => {
            onSubmit(values).then(() => {
                formik.resetForm()
            })
        },
    })
    
    function generate() {
        formik.setFieldValue('password', generatePass())
    }
    
    const { login, password, curGroup, contract } = formik.values
    const { data, isLoading, isFetching } = useGetGroupsQuery()
    
    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid">
            {update ? null : (
                <FormItem
                    value={login}
                    name={'login'}
                    label={'Логин'}
                    formik={formik}
                >
                    <InputText />
                </FormItem>
            )}
            <FormItem
                value={contract}
                name={'contract'}
                label={'Договор'}
                formik={formik}
            >
                <InputText />
            </FormItem>
            <FormItem
                value={curGroup}
                name={'curGroup'}
                label={'Группа'}
                formik={formik}
            >
                <Dropdown
                    loading={isLoading || isFetching}
                    options={map(data, ({ id, curGroup }) => ({
                        value: id,
                        label: curGroup,
                    }))}
                />
            </FormItem>
            <FormItem
                value={password}
                name={'password'}
                label={'Пароль'}
                formik={formik}
            >
                <InputText />
            </FormItem>
            <Button
                type={'button'}
                className={'mb-24'}
                style={{ width: 'auto', display: 'block' }}
                onClick={generate}
            >
                Сгенерировать пароль
            </Button>
            <Button
                loading={loading}
                type="submit"
                label={update ? 'Обновить' : 'Сохранить'}
                className={'w-auto'}
            />
        </form>
    )
}

export type Source = 'Уроки' | 'Библиотека' | 'Видео' | 'Статьи'

export enum SourceColor {
  'Уроки' = '#3B82F6',
  'Библиотека' = '#D97706',
  'Видео' = '#16A34A',
  'Статьи' = '#8D44AF',
}

export interface IMaterial {
  id: number
  previewImage: string
  createdDate: string
  theme: string
  name: string
  source: Source
  pathFileMaterial: string
}

export interface ISubject {
  id: number
  name: string
  themes: string[]
}

export interface IMaterialData {
  lastMaterials: IMaterial[]
  materials: IMaterial[]
  subjects: ISubject[]
}

export interface IMaterialState {
  data: IMaterialData
  isLoading: boolean
  hasLoaded: boolean
}

import { TeacherPaths, DirectorCompanyPaths, DirectorFilialPaths, EduPaths, StudentPaths } from './Routing.types'
import { AttendantPageLessonView } from 'src/pages/Attendant/types/AttendantPageLessonView'
import { AttendantPageGroupView } from 'src/pages/Attendant/types/AttendantPageGroupView'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { AttendanceStatistic } from 'src/pages/edu/AttendanceStatistic'
import StudentTimetablePage from 'src/pages/student/TimetablePage'
import { AdminHomeworkPage } from 'src/pages/admin/Homework'
import { NotificationsPage } from 'src/pages/Notifications'
import IndexStudentPage from 'src/pages/student/IndexPage'
import ProgressPage from 'src/pages/student/ProgressPage'
import HomeworkPage from 'src/pages/student/HomeworkPage'
import { PracticePage } from 'src/pages/student/Practice'
import MaterialPage from 'src/pages/student/MaterialPage'
import ProfilePage from 'src/pages/student/ProfilePage'
import { AttendantPage } from 'src/pages/Attendant'
import { TimetablePage } from 'src/pages/Timetable'
import { Statistic } from 'src/pages/edu/Statistic'
import { MaterialsPage } from 'src/pages/Materials'
import { Students } from 'src/pages/edu/Students'
import { Audience } from 'src/pages/edu/Audience'
import { EduGroups } from 'src/pages/edu/Groups'
import { Role } from 'src/store/user/user.types'
import ErrorPage from 'src/pages/ErrorPage'
import { MailPage } from 'src/pages/Mail'
import AuthPage from 'src/pages/AuthPage'
import React, { ReactNode } from 'react'
import { Users } from 'src/pages/Users'
import { EduSubjects } from 'src/pages'
import { map } from 'lodash'

interface Route<RolePath> {
    path: RolePath;
    element: ReactNode;
}

type Routes<RolePath> = Array<Route<RolePath>>;

const studentRoutes: Routes<StudentPaths> = [
    { path: StudentPaths.index, element: <IndexStudentPage /> },
    { path: StudentPaths.progress, element: <ProgressPage /> },
    {
        path: StudentPaths.materials,
        element: <MaterialPage key={'materials'} />,
    },
    { path: StudentPaths.homework, element: <HomeworkPage /> },
    { path: StudentPaths.timetable, element: <StudentTimetablePage /> },
    { path: StudentPaths.profile, element: <ProfilePage /> },
    { path: StudentPaths.mail, element: <MailPage student /> },
    { path: StudentPaths.notifications, element: <NotificationsPage /> },
    {
        path: StudentPaths.practices,
        element: <PracticePage />,
    },
]

const teacherRoutes: Routes<TeacherPaths> = [
    { path: TeacherPaths.attendant, element: <AttendantPage allowEdit={true} /> },
    { path: TeacherPaths.materials, element: <MaterialsPage /> },
    {
        path: TeacherPaths.timetable,
        element: <TimetablePage allowEdit={false} />,
    },
    { path: TeacherPaths.profile, element: <ProfilePage /> },
    { path: TeacherPaths.homework, element: <AdminHomeworkPage /> },
    { path: TeacherPaths.notifications, element: <NotificationsPage /> },
]

const eduRoutes: Routes<EduPaths> = [
    {
        path: EduPaths.timetable,
        element: <TimetablePage allowEdit />,
    },
    {
        path: EduPaths.attendant,
        element: <AttendantPage />,
    },
    {
        path: EduPaths.attendantLessonView,
        element: <AttendantPageLessonView />,
    },
    {
        path: EduPaths.attendantGroupView,
        element: <AttendantPageGroupView />,
    },
    { path: EduPaths.materials, element: <MaterialsPage allowEdit /> },
    { path: EduPaths.subjects, element: <EduSubjects /> },
    { path: EduPaths.notifications, element: <NotificationsPage /> },
    { path: EduPaths.groups, element: <EduGroups /> },
    { path: EduPaths.students, element: <Students /> },
    { path: EduPaths.mail, element: <MailPage /> },
    { path: EduPaths.classrooms, element: <Audience /> },
    { path: EduPaths.statisticsDetails, element: <Statistic /> },
    {
        path: EduPaths.attendanceStatisticDetails,
        element: <AttendanceStatistic />,
    },
]

const directorFilialPaths: Routes<DirectorFilialPaths> = [
    {
        path: DirectorFilialPaths.students,
        element: <Students />,
    },
    {
        path: DirectorFilialPaths.users,
        element: <Users />,
    },
    {
        path: DirectorFilialPaths.timetable,
        element: <TimetablePage allowEdit />,
    },
    {
        path: DirectorFilialPaths.attendant,
        element: <AttendantPage />,
    },
    {
        path: DirectorFilialPaths.materials,
        element: <MaterialsPage allowEdit />,
    },
    { path: DirectorFilialPaths.subjects, element: <EduSubjects /> },
    { path: DirectorFilialPaths.notifications, element: <NotificationsPage /> },
    { path: DirectorFilialPaths.groups, element: <EduGroups /> },
    { path: DirectorFilialPaths.mail, element: <MailPage /> },
    { path: DirectorFilialPaths.classrooms, element: <Audience /> },
]

const directorCompany: Routes<DirectorCompanyPaths> = [
    {
        path: DirectorCompanyPaths.users,
        element: <Users directorCompany />,
    },
    { path: DirectorCompanyPaths.mail, element: <MailPage /> },
]

const routes: Partial<Record<Role, any>> = {
    student: studentRoutes,
    admin: teacherRoutes,
    edu: eduRoutes,
    director_company: directorCompany,
    director_filial: directorFilialPaths,
}

export type RoutingProps = {
    role: Role;
    profileCompleted: boolean;
}

export const Routing: React.FC<RoutingProps> = ({ role, profileCompleted }) => {
    if (role in routes) {
        if (role === 'student' && !profileCompleted)
            return (
                <BrowserRouter>
                    <Routes>
                        <Route path="/profile/" element={<ProfilePage />} />
                        <Route path={'/error'} element={<ErrorPage />} />
                        <Route
                            path="*"
                            element={<Navigate to={'/profile/'} />}
                        />
                    </Routes>
                </BrowserRouter>
            )
        return (
            <BrowserRouter>
                <Routes>
                    {map(role !== 'anonymous' ? routes[role] : [], route => {
                        return <Route key={route.path} {...route} />
                    })}
                    <Route path={'/error'} element={<ErrorPage />} />
                    <Route
                        path="*"
                        element={
                            <Navigate
                                to={
                                    role !== 'anonymous'
                                    ? routes[role][0].path
                                    : '/'
                                }
                            />
                        }
                    />
                </Routes>
            </BrowserRouter>
        )
    }
    
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login/" element={<AuthPage />} />
                <Route path="*" element={<Navigate to={'/login/'} />} />
            </Routes>
        </BrowserRouter>
    )
}

import styles from '../AttendantPage.module.scss';
import {
    AttendantStudent,
    Status,
} from '../../../store/admin/attendant/attendant.types';
import React, { ReactNode } from 'react';
import { EducationClassType } from '../AttendantPage';

export function num_word(value: number, words: Array<string>) {
    value = Math.abs(value) % 100;
    const num = value % 10;
    if (value > 10 && value < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if (num === 1) return words[0];
    return words[2];
}

export function getMarkClassname(mark: number) {
    return mark === 0
        ? styles.noMark
        : mark >= 1 && mark < 4
          ? styles.bad
          : mark > 3 && mark < 8
            ? styles.ok
            : styles.good;
}

export function isDisabled(record: RowData, classType?: EducationClassType) {
    return (
        record.presentStudent.status === Status.absent ||
        record.presentStudent.status === null ||
        !classType
    );
}

export const marks = [
    'Нет оценки',
    ...Array.from({ length: 10 }, (_, i) => {
        const num = i + 1;
        return `${num} ${num_word(num, ['балл', 'балла', 'баллов'])}`;
    }),
];

export const Statuses = {
    0: styles.good,
    2: styles.absence,
    3: styles.noMark,
};

export type RowData = AttendantStudent;

export const Label = ({ children }: { children: ReactNode }) => {
    return <span className={styles.label}>{children}</span>;
};

export const FormItem = ({
    label,
    children,
}: {
    label: ReactNode;
    children: ReactNode;
}) => {
    return (
        <div className={styles.formItem}>
            <Label>{label}</Label>
            {children}
        </div>
    );
};

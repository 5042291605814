import { FC, useEffect, useRef, useState } from 'react';
import './AuthPage.scss';
import logo from '../../static/logo.svg';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import useAppDispatch from '../../hooks/useAppDispatch';
import { login } from '../../store/user/user.actions';
import { Toast } from 'primereact/toast';
import {
    connect,
    connectToMessages,
    connectToNotifications,
    connectToPractices,
    Topic,
} from '../../utils/ws/ws';
import { actions } from '../../store/user/user.slice';
import { baseApi } from '../../api';
import { NotificationTypes, notify } from 'src/utils/notify';

const {
    addNotification,
    removeNotification,
    addMessage,
    removeMessage,
    addPractice,
    removePractice,
} = actions;
export const AuthPage: FC = () => {
    const [buttonLoading, setButtonLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const dispatch = useAppDispatch();
    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        validate(data) {
            const errors: {
                login?: string;
                password?: string;
            } = {};

            if (!data.login.trim().length) errors.login = 'Логин обязателен!';
            if (!data.password.trim().length)
                errors.password = 'Пароль обязателен!';

            return errors;
        },
        onSubmit(data) {
            setButtonLoading(true);

            dispatch(login(data))
                .unwrap()
                .then(response => {
                    const topics: Array<Topic> = [];
                    const role = response.role;
                    topics.push(
                        ...connectToNotifications(
                            response.id,
                            notification =>
                                dispatch(addNotification(notification)),
                            notifications =>
                                dispatch(removeNotification(notifications))
                        )
                    );
                    if (role === 'student') {
                        topics.push(
                            ...connectToPractices(
                                response.id,
                                () => {
                                    dispatch(addPractice());
                                    dispatch(
                                        baseApi.util.invalidateTags([
                                            'Practice',
                                        ])
                                    );
                                },
                                () => {
                                    dispatch(removePractice());
                                    dispatch(
                                        baseApi.util.invalidateTags([
                                            'Practice',
                                        ])
                                    );
                                }
                            )
                        );
                    }
                    if (
                        response.role !== 'admin' &&
                        response.role !== 'student'
                    )
                        topics.push(
                            ...connectToMessages(
                                response.id,
                                () => {
                                    dispatch(addMessage());
                                    dispatch(
                                        baseApi.util.invalidateTags([
                                            'ChatMessage',
                                        ])
                                    );
                                },
                                () => {
                                    dispatch(removeMessage());
                                    dispatch(
                                        baseApi.util.invalidateTags([
                                            'ChatMessage',
                                        ])
                                    );
                                }
                            )
                        );
                    connect(topics);
                    setButtonLoading(false);
                })
                .catch(error => {
                    console.log('ERROR', error);

                    setButtonLoading(false);
                    notify(
                        window.Toast,
                        {
                            type: NotificationTypes.error,
                            content: error,
                        },
                        true
                    );
                });
        },
    });
    const toastRef = useRef<Toast>(null);

    const isFormFieldValid = (name: 'login' | 'password') =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name: 'login' | 'password') => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    useEffect(() => {
        document.title = 'Авторизация';
    }, []);

    const toggleVisible = () => setVisible(prev => !prev);

    return (
        <div className="w-full container flex align-items-center justify-content-center auth-page">
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-column align-items-center form"
            >
                <div className="logo">
                    <img src={logo} alt="logo" />
                </div>

                <div className="field first">
                    <span className="p-float-label">
                        <InputText
                            id="login"
                            value={formik.values.login}
                            onChange={formik.handleChange}
                            className={
                                classNames({
                                    'p-invalid': isFormFieldValid('login'),
                                }) + ' p-inputtext-lg block'
                            }
                        />
                        <label
                            htmlFor="login"
                            className={classNames({
                                'p-error': isFormFieldValid('login'),
                            })}
                        >
                            Логин
                        </label>
                    </span>
                    {getFormErrorMessage('login')}
                </div>
                <div className="field flex flex-column">
                    <span className="p-float-label p-input-icon-right">
                        {visible ? (
                            <i className="pi pi-eye" onClick={toggleVisible} />
                        ) : (
                            <i
                                className="pi pi-eye-slash"
                                onClick={toggleVisible}
                            />
                        )}
                        <InputText
                            id="password"
                            type={visible ? 'text' : 'password'}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            className={
                                classNames({
                                    'p-invalid': isFormFieldValid('password'),
                                }) + ' p-inputtext-lg block'
                            }
                        />
                        <label
                            htmlFor="password"
                            className={classNames({
                                'p-error': isFormFieldValid('password'),
                            })}
                        >
                            Пароль
                        </label>
                    </span>
                    {getFormErrorMessage('password')}
                </div>

                <Button type="submit" loading={buttonLoading}>
                    Войти
                </Button>
            </form>
            <Toast ref={toastRef} />
        </div>
    );
};

import { Button, ButtonProps } from 'primereact/button';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { FC } from 'react';
import { confirmTooltip } from 'src/utils/confirm';

export const DeleteButton: FC<ButtonProps> = ({
    onClick,
    icon = 'pi pi-times',
    ...props
}) => {
    const deleteHandler = event => {
        if (event)
            return confirmTooltip(event.currentTarget, {
                message: 'Вы уверены что хотите удалить?',
                accept: onClick?.bind(null, event),
            });
    };

    return (
        <>
            <Button onClick={deleteHandler} icon={icon} {...props} />
            <ConfirmPopup />
        </>
    );
};

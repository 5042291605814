import { baseApi } from '../../baseApi';
import { Group } from './group.types';

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getGroups: builder.query<Array<Group>, void | null>({
            providesTags: ['Group'],
            query: _ => ({
                url: '/groups',
                method: 'get',
                errorMessage: 'Не удалось получить информацию о группах!',
            }),
            transformResponse(val: any) {
                return val.map(el => ({
                    id: el.id,
                    curGroup: el.name,
                }));
            },
        }),
        addGroup: builder.mutation<void, { name: string }>({
            invalidatesTags: ['Group'],
            query: arg => ({
                url: '/groups',
                method: 'post',
                data: arg,
                errorMessage: 'Не удалось создать группу!',
            }),
        }),
    }),
});

export const { useGetGroupsQuery, useAddGroupMutation } = api;

import { useGetDataForCreateTimetableQuery } from 'src/api/endpoints/timetable';
import React, { FC, MouseEventHandler, RefObject, useRef } from 'react';
import { requireField } from 'src/constants/constants';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { Dropdown } from 'src/components/Dropdown';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Type } from '../TimetablePage';
import { useFormik } from 'formik';
import { map } from 'lodash';

interface ModalProps {
    onSubmit: (values: Type) => void;
    onDelete: MouseEventHandler<HTMLButtonElement>;
    initialValues: Type;
    update: boolean;
    loading: boolean;
    deleteLoading: boolean;
}

export const ModalContent: FC<ModalProps> = ({
    onSubmit,
    initialValues,
    onDelete,
    update,
    loading,
    deleteLoading,
}: ModalProps) => {
    const toast: RefObject<Toast> = useRef<Toast>(null);

    const formik = useFormik<Type>({
        initialValues,
        validate: data => {
            const errors: Partial<Record<keyof Type, any>> = {};
            if (!data?.cur_group) errors.cur_group = requireField;
            if (!data?.subject) errors.subject = requireField;
            if (!data?.start_date) errors.start_date = requireField;
            if (!data?.end_date) errors.end_date = requireField;
            if (!data?.audience) errors.audience = requireField;
            if (!data?.teacher) errors.teacher = requireField;
            if (
                data?.range &&
                ((data.range[0] && !data.range[1]) ||
                    (data.range[1] && !data.range[0]))
            )
                errors.range = 'Введите начало и конец промежутка!';
            return errors;
        },
        onSubmit,
    });

    const isFormFieldValid = (name: keyof Type) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: keyof Type) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const { data: modalData, isLoading } = useGetDataForCreateTimetableQuery();

    return (
        <>
            <Toast ref={toast} />
            <form onSubmit={formik.handleSubmit} className="p-fluid">
                <div className="field flex gap-24">
                    <div className={'flex-grow-1'}>
                        <span className="p-float-label">
                            <Calendar
                                mask="99:99"
                                timeOnly
                                id="start_date"
                                name="start_date"
                                maxDate={formik.values.end_date}
                                value={formik.values.start_date}
                                onChange={formik.handleChange}
                                className={classNames({
                                    'p-invalid': isFormFieldValid('start_date'),
                                })}
                            />
                            <label
                                htmlFor="start_date"
                                className={classNames({
                                    'p-error': isFormFieldValid('start_date'),
                                })}
                            >
                                Начало
                            </label>
                        </span>
                        {getFormErrorMessage('start_date')}
                    </div>
                    <div className={'flex-grow-1'}>
                        <span className="p-float-label">
                            <Calendar
                                mask="99:99"
                                timeOnly
                                id="end_date"
                                name="end_date"
                                minDate={formik.values.start_date}
                                value={formik.values.end_date}
                                onChange={formik.handleChange}
                                className={classNames({
                                    'p-invalid': isFormFieldValid('end_date'),
                                })}
                            />
                            <label
                                htmlFor="end_date"
                                className={classNames({
                                    'p-error': isFormFieldValid('end_date'),
                                })}
                            >
                                Конец
                            </label>
                        </span>
                        {getFormErrorMessage('end_date')}
                    </div>
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <Dropdown
                            filter
                            id="audience"
                            name="audience"
                            loading={isLoading}
                            options={map(
                                modalData?.audiences,
                                ({ id, name }) => ({
                                    label: name,
                                    value: id,
                                })
                            )}
                            value={formik.values.audience}
                            onChange={formik.handleChange}
                            className={classNames({
                                'p-invalid': isFormFieldValid('audience'),
                            })}
                        />
                        <label
                            htmlFor="audience"
                            className={classNames({
                                'p-error': isFormFieldValid('audience'),
                            })}
                        >
                            Аудитория
                        </label>
                    </span>
                    {getFormErrorMessage('audience')}
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <Dropdown
                            filter
                            id="cur_group"
                            name="cur_group"
                            loading={isLoading}
                            options={map(
                                modalData?.dataNameGroups,
                                ({ id, curGroup }) => ({
                                    label: curGroup,
                                    value: id,
                                })
                            )}
                            value={formik.values.cur_group}
                            onChange={formik.handleChange}
                            className={classNames({
                                'p-invalid': isFormFieldValid('cur_group'),
                            })}
                        />
                        <label
                            htmlFor="cur_group"
                            className={classNames({
                                'p-error': isFormFieldValid('cur_group'),
                            })}
                        >
                            Группа
                        </label>
                    </span>
                    {getFormErrorMessage('cur_group')}
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <Dropdown
                            filter
                            id="teacher"
                            name="teacher"
                            loading={isLoading}
                            options={map(
                                modalData?.teachers,
                                ({ id, fio }) => ({
                                    label: fio,
                                    value: id,
                                })
                            )}
                            value={formik.values.teacher}
                            onChange={formik.handleChange}
                            className={classNames({
                                'p-invalid': isFormFieldValid('teacher'),
                            })}
                        />
                        <label
                            htmlFor="teacher"
                            className={classNames({
                                'p-error': isFormFieldValid('teacher'),
                            })}
                        >
                            Преподаватель
                        </label>
                    </span>
                    {getFormErrorMessage('teacher')}
                </div>
                <div className="field">
                    <span className="p-float-label">
                        <Dropdown
                            filter
                            id="subject"
                            name="subject"
                            loading={isLoading}
                            options={map(
                                modalData?.subjects,
                                ({ id, name }) => ({
                                    value: id,
                                    label: name,
                                })
                            )}
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            className={classNames({
                                'p-invalid': isFormFieldValid('subject'),
                            })}
                        />
                        <label
                            htmlFor="subject"
                            className={classNames({
                                'p-error': isFormFieldValid('subject'),
                            })}
                        >
                            Предмет
                        </label>
                    </span>
                    {getFormErrorMessage('subject')}
                </div>
                {initialValues?.range ? (
                    <div className="field">
                        <span className="p-float-label">
                            <Calendar
                                value={formik.values.range}
                                onChange={formik.handleChange}
                                id="range"
                                name="range"
                                className={classNames({
                                    'p-invalid': isFormFieldValid('range'),
                                })}
                                selectionMode="range"
                                readOnlyInput
                                showButtonBar
                                // dateTemplate={e => {
                                //     const date = dayWorker(
                                //         new Date(e.year, e.month, e.day)
                                //     );
                                //     const isHighlight =
                                //         e.today || start.isSame(date, 'day');
                                //     return (
                                //         <span
                                //             className={classNames({
                                //                 'p-highlight': isHighlight,
                                //             })}
                                //             tabIndex="0"
                                //         >
                                //             {e.day}
                                //         </span>
                                //     );
                                // }}
                                viewDate={formik.values.start_date}
                            />
                            <label
                                htmlFor="range"
                                className={classNames({
                                    'p-error': isFormFieldValid('range'),
                                })}
                            >
                                Проставить на
                            </label>
                        </span>
                        {getFormErrorMessage('range')}
                    </div>
                ) : null}
                <Button
                    loading={loading}
                    type="submit"
                    label="Сохранить"
                    className={'w-auto'}
                />
                <ConfirmPopup />
                {update ? (
                    <Button
                        loading={deleteLoading}
                        type={'button'}
                        onClick={onDelete}
                        label="Удалить"
                        className={'w-auto ml-24'}
                    />
                ) : null}
            </form>
        </>
    );
};

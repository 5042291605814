import { useGetStatisticsQuery } from '../../../api';
import { Link, useParams } from 'react-router-dom';
import { StudentLayout } from '../../../components/Layout/student/StudentLayout';
import { Helmet } from 'react-helmet';
import { Card } from '../../../components/Card/Card';
import Loader from '../../../components/Loader';
import React, { ComponentPropsWithoutRef, ReactNode, useRef } from 'react';
import styles from '../../Attendant/AttendantPage.module.scss';
import { map, size } from 'lodash';
import { Lesson } from '../../../components/Lesson/Lesson';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { ColorIndicator } from '../../../components/ColorIndicator';
import { EduPaths } from '../../../components/Routing/Routing.types';

export const AttendanceStatistic = () => {
    const { id } = useParams();
    const { isLoading, data } = useGetStatisticsQuery({
        studentId: Number(id),
    });
    return (
        <StudentLayout>
            <Helmet title={'Посещаемость'} />
            <Card
                headerClassName={'mt-24'}
                header={'Посещаемость'}
                contentClassName={'p-24'}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        Урок №
                        <div
                            className={classNames(styles.classWrapper, 'mt-3')}
                        >
                            {size(data?.statisticLessonModels)
                                ? map(
                                      data?.statisticLessonModels,
                                      ({
                                          numberLesson,
                                          lessonId,
                                          status,
                                          shortInfoLesson,
                                      }) => (
                                          <TooltipLesson
                                              to={`${EduPaths.attendant}/${lessonId}/${shortInfoLesson.curGroup}/${shortInfoLesson.curGroup}`}
                                              time={shortInfoLesson.time}
                                              teacher={
                                                  shortInfoLesson.fioTeacher
                                              }
                                              subject={shortInfoLesson.subject}
                                              absent={status === 2}
                                              late={status === 3}
                                              key={lessonId}
                                              children={numberLesson}
                                          />
                                      )
                                  )
                                : 'Уроки отсутсвуют'}
                        </div>
                        <div className={'mt-3'}>
                            <ColorIndicator text={'Студент был'} />
                            <ColorIndicator danger text={'Студента не было'} />
                            <ColorIndicator info text={'Студент опоздал'} />
                        </div>
                    </div>
                )}
            </Card>
        </StudentLayout>
    );
};

const TooltipLesson = ({
    to,
    time,
    teacher,
    subject,
    ...rest
}: ComponentPropsWithoutRef<typeof Lesson> & {
    to: string;
    time: ReactNode;
    teacher: ReactNode;
    subject: ReactNode;
}) => {
    const ref = useRef<HTMLButtonElement>(null);

    return (
        <>
            {ref.current ? (
                <Tooltip position={'top'} target={ref.current} autoHide={false}>
                    <div className={'flex flex-column gap-2'}>
                        <div>Предмет: {subject}</div>
                        <div>Время: {time}</div>
                        <div>Преподаватель: {teacher}</div>
                        <Link style={{ display: 'block' }} to={to}>
                            <Button
                                className={'flex w-full justify-content-center'}
                            >
                                Открыть
                            </Button>
                        </Link>
                    </div>
                </Tooltip>
            ) : null}
            <Lesson ref={ref} {...rest} />
        </>
    );
};

import { baseApi } from '../../baseApi';
import {
    GetShortStatisticsArg,
    GetShortStatisticsResult,
    GetStatisticsArg,
    GetStatisticsResult,
} from './statistics.types';

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getShortStatistics: builder.query<
            GetShortStatisticsResult,
            GetShortStatisticsArg
        >({
            providesTags: ['Practice'],
            query: arg => {
                return {
                    url: 'statistics/short',
                    method: 'get',
                    params: {
                        userId: arg.studentId,
                    },
                    errorMessage:
                        'Не удалось получить информацию о практических работах!',
                };
            },
            transformResponse(val: any) {
                return {
                    shortInfoData: {
                        curGroup: val.profile.group.name,
                        coins: val.profile.coins,
                        stars: 0,
                        student: {
                            id: val.profile.user.id,
                            role: val.profile.user.role.name,
                            firstName: val.profile.user.firstname,
                            lastName: val.profile.user.lastname,
                            patronymicName: val.profile.user.patronymic,
                            avatarURL: val.profile.user.avatar,
                            permissions: {
                                canAttachPackage: false,
                            },
                        },
                    },
                    visitsModel: {
                        present: val.attendance.present,
                        countLesson: val.attendance.countLesson,
                    },
                    infoData: {
                        points: {
                            curMeanPoint: val.performance.marks.average.current,
                            prevMeanPoints:
                                val.performance.marks.average.previous,
                            points: val.performance.marks.last.map(el => ({
                                point: el.mark,
                                date: el.date,
                            })),
                        },

                        homework: {
                            allTasks: val.performance.homework?.total || 0,
                            curTasks: val.performance.homework?.upcoming || 0,
                            passedTasks: val.performance.homework?.expired || 0,
                            completedTasks:
                                val.performance.homework?.completed || 0,
                            curTask: val.performance.homework?.currentTask?.name
                                ? {
                                      name: val.performance.homework.currentTask
                                          .name,
                                      description:
                                          val.performance.homework.currentTask
                                              .description,
                                      tags: '',
                                      deadline:
                                          val.performance.homework.currentTask
                                              .expiryAt,
                                  }
                                : undefined,
                        },

                        materials: val.performance.material?.id
                            ? {
                                  previewImage: val.performance.material.image,
                                  createdDate:
                                      val.performance.material.createdAt,
                                  theme: val.performance.material.theme,
                                  name: val.performance.material.name,
                                  source: val.performance.material.source,
                              }
                            : undefined,

                        timetable: val.performance.lesson?.time
                            ? {
                                  datetime: val.performance.lesson.time,
                                  theme: val.performance.lesson.theme,
                              }
                            : undefined,
                    },
                };
            },
        }),
        getStatistics: builder.query<GetStatisticsResult, GetStatisticsArg>({
            providesTags: ['Practice'],
            query: arg => ({
                url: 'statistics',
                params: {
                    userId: arg.studentId,
                },
                method: 'get',
                errorMessage:
                    'Не удалось получить информацию о количестве практических работ!',
            }),
            transformResponse(val: any) {
                return {
                    statisticLessonModels: val.lessons.map(el => ({
                        lessonId: el.id,
                        numberLesson: el.number,
                        status: el.status,
                        shortInfoLesson: {
                            curGroup: el.lesson.group.id,
                            subjectId: el.lesson.subject.id,
                            subject: el.lesson.subject.name,
                            time: el.lesson.startedAt,
                            fioTeacher: el.lesson.teacher.fio,
                        },
                    })),
                    typeStatuses: val.statuses.map(el => ({
                        statusId: el.id,
                        status: el.status,
                    })),
                };
            },
        }),
    }),
});

export const { useGetShortStatisticsQuery, useGetStatisticsQuery } = api;

import { RequestState } from '../../../constants/constants';
import { Material } from '../materials/materials.types';

export interface IAttendantSlice {
    getMaterials: RequestState;
    uploadMaterialAndHomework: RequestState;
    getNearestDataLesson: RequestState;
    typeMarks: Array<AttendantMark>;
    typeStatuses: Array<AttendantLessonStatus>;
    materials: Material[];
    nearestLessons: Array<INearestLesson>;
}

export interface Form {
    name: string;
}

export interface AttendantGroup {
    group: string;
    filtersTeacherSubjects: Array<AttendantSubject>;
}

export interface AttendantSubject {
    subject: string;
    subjectId: number;
    filtersTeacherLessons: Array<AttendantLesson>;
}

export interface AttendantLesson {
    numberLesson: number;
    lessonId: number;
    subjectTheme: string;
    active: boolean;
}

export interface AttendantMark {
    typeId: number;
    type: string;
}

export interface AttendantLessonStatus {
    statusId: number;
    status: string;
}

export interface PresentStudentData {
    id: number;
    userId: number;
    status: Status;
    mark: number;
    reward: number;
    comment: string | null;
    typeMark: number;
    presentLessonId: number;
    theme: string | null;
}

export interface AttendantStudent {
    fio: string;
    presentStudent: PresentStudentData;
}

export interface MethodPackageModel {
    deadline: string;
    material: Material;
}

export enum Status {
    attendant,
    absent,
    late,
}

export interface INearestLesson {
    academicDataLessonTeacher: {
        academicStudentModels: Array<AttendantStudent>;
        methodPackageModel: MethodPackageModel | null;
    };
    lessons: {
        startDate: string;
        endDate: string;
        subject: string;
        group: string;
        groupId: number;
        audienceName: string;
        active: boolean;
        // now: boolean
        lessonId: number;
    };
}

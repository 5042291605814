import React, { FC, MouseEventHandler, useMemo, useRef, useState } from 'react';
import { StudentLayout } from 'src/components/Layout/student/StudentLayout';
import { RemoveScheduleModal } from './components/RemoveScheduleModal';
import { NotificationTypes, notify } from 'src/utils/notify';
import { dateFormat } from 'src/constants/constants';
import { confirmTooltip } from 'src/utils/confirm';
import { Modal } from 'src/components/Modal/Modal';
import { Calendar } from 'src/components/Calendar';
import { Dropdown } from 'src/components/Dropdown';
import { ModalContent } from './components/Modal';
import { Card } from 'src/components/Card/Card';
import style from './TimetablePage.module.scss';
import { timeSlots } from './config/config';
import { Table } from './components/Table';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { forEach, map } from 'lodash';
import { dayWorker } from 'src/utils';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import {
    Lesson,
    RemoveTimetableRangeArg,
    useCreateLessonMutation,
    useDeleteTimetableMutation,
    useDeleteTimetableRangeMutation,
    useGetDataForCreateTimetableQuery,
    useGetTimetableScheduleQuery,
    useUpdateTimetableMutation,
} from 'src/api/endpoints/timetable';

function formatTime(date: Dayjs, time: string): Dayjs {
    const timeObj: Dayjs = dayWorker(time, 'HH:mm');
    return date
        .clone()
        .set('hour', timeObj.get('hour'))
        .set('minute', timeObj.get('minute'));
}

function formatRange(date: Date, fromDate: Date): number {
    return dayWorker(date)
        .set('minutes', dayWorker(fromDate).get('minutes'))
        .set('hour', dayWorker(fromDate).get('hour'))
        .toDate()
        .getTime();
}

export type Type = Partial<{
    start_date: Date;
    end_date: Date;
    subject: number;
    cur_group: number;
    teacher: number;
    audience: number;
    range: Date[];
}>;

export interface ScheduleRow {
    time: string;
    index: number;

    [key: number]: Array<Lesson>;
}

interface TimetablePageProps {
    allowEdit?: boolean;
}

export const TimetablePage: FC<TimetablePageProps> = ({ allowEdit }) => {
    const [modal, setModal] = useState(false);
    const [removeScheduleModal, setRemoveScheduleModal] = useState(false);
    const [activeId, setActiveId] = useState<number | null>(null);
    const [activeLesson, setActiveLesson] = useState<number | null>(null);
    const [initialValues, setInitialValues] = useState<Type | null>(null);
    const [group, setGroup] = useState<null | number>(null);
    const [audience, setAudience] = useState<null | number>(null);
    const [teacher, setTeacher] = useState<null | number>(null);
    const toast = useRef<Toast>(null);
    const [range, setRange] = useState({
        start: dayWorker().startOf('week'),
        end: dayWorker().endOf('week'),
    });

    const { data, isFetching } = useGetTimetableScheduleQuery({
        start_date: range.start.format(dateFormat),
        end_date: range.end.format(dateFormat),
        audience,
        cur_group: group,
        teacherId: teacher,
    });

    const [deleteLessons, { isLoading: isDeleteRangeLoading }] =
        useDeleteTimetableRangeMutation();
    const [createLesson, { isLoading: isCreateLoading }] =
        useCreateLessonMutation();
    const [updateLesson, { isLoading: isUpdateLoading }] =
        useUpdateTimetableMutation();
    const [deleteLesson, { isLoading: isDeleteLoading }] =
        useDeleteTimetableMutation();

    const { data: selectData, isLoading } = useGetDataForCreateTimetableQuery(
        undefined,
        {
            skip: !allowEdit,
        }
    );

    function nextWeek(): void {
        const obj: Dayjs = dayWorker(range.start).add(1, 'week');

        setRange({
            start: obj.startOf('week'),
            end: obj.endOf('week'),
        });
    }

    const prevWeek = (): void => {
        const obj: Dayjs = dayWorker(range.start).subtract(1, 'week');

        setRange({
            start: obj.startOf('week'),
            end: obj.endOf('week'),
        });
    };

    const schedule = useMemo(() => {
        const rows: Array<ScheduleRow> = map(
            timeSlots,
            ([start, end], index) => {
                return {
                    time: `${start}-${end}`,
                    index: index + 1,
                    ...Array.from({ length: 7 }, () => []),
                };
            }
        );

        forEach(data, lesson => {
            const { numberLesson, startDate } = lesson;
            const numberRow: ScheduleRow = rows[numberLesson];
            const dayOfWeek: number = dayWorker(startDate).weekday();

            numberRow[dayOfWeek] = [...numberRow[dayOfWeek], lesson];
        });
        return rows;
    }, [data]);
    function onCreate(form: Type): void {
        if (activeLesson === null) return;

        const startDate =
            form.range![0] && formatRange(form.range![0], form.start_date!);
        const endDate =
            form.range![1] && formatRange(form.range![1], form.end_date!);

        console.log('FORM', form, {
            audience: form.audience!,
            cur_group: Number(form.cur_group),
            number_lesson: activeLesson,
            subject: form.subject!,
            start_date: startDate,
            end_date: endDate,
            rangeDateStart: startDate,
            rangeDateEnd: endDate,
            teacherId: form.teacher!,
        });

        createLesson({
            audience: form.audience!,
            cur_group: Number(form.cur_group),
            number_lesson: activeLesson,
            subject: form.subject!,
            start_date: startDate,
            end_date: endDate,
            rangeDateStart: startDate,
            rangeDateEnd: endDate,
            teacherId: form.teacher!,
        })
            .unwrap()
            .then((): void => {
                notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Вы успешно создали урок!',
                    },
                    true
                );

                onHide();
            })
            .catch(error => {
                notify(
                    toast,
                    {
                        type: NotificationTypes.error,
                        content: error.data,
                    },
                    true
                );

                onHide();
            });
    }

    function onUpdate(form: Type): void {
        if (!activeId || activeLesson === null) return;

        updateLesson({
            audience: form.audience!,
            cur_group: form.cur_group!,
            id: activeId,
            subject: form.subject!,
            start_date: form.start_date!.getTime(),
            end_date: form.end_date!.getTime(),
            teacherId: form.teacher!,
        })
            .unwrap()
            .then((): void => {
                notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Вы успешно обновили урок!',
                    },
                    true
                );
                onHide();
            });
    }

    const onDelete: MouseEventHandler<HTMLButtonElement> = event => {
        function request(): void {
            if (!activeId) return;

            deleteLesson({ lessonId: activeId })
                .unwrap()
                .then((): void => {
                    notify(
                        toast,
                        {
                            type: NotificationTypes.success,
                            content: 'Вы успешно удалили урок!',
                        },
                        true
                    );

                    onHide();
                })
                .catch(error => {
                    // console.log('Update Error', error);
                });
        }

        if (event)
            return confirmTooltip(event.currentTarget, {
                message: 'Вы уверены что хотите удалить?',
                accept: request,
            });
        request();
    };

    function onHide() {
        setActiveId(null);
        setInitialValues(null);
        setModal(false);
        setActiveLesson(null);
    }

    function onAdd(column: ScheduleRow, date: dayWorker.Dayjs) {
        if (!allowEdit) return;

        const startDate = formatTime(
            date,
            timeSlots[column.index - 1][0]
        ).toDate();
        const endDate = formatTime(
            date,
            timeSlots[column.index - 1][1]
        ).toDate();

        setModal(true);
        setActiveLesson(column.index - 1);
        setInitialValues({
            start_date: startDate,
            end_date: endDate,
            subject: undefined,
            cur_group: undefined,
            teacher: undefined,
            range: [startDate, endDate],
        });
    }

    function onEdit(column: ScheduleRow, lesson: Lesson) {
        setModal(true);
        setActiveId(lesson.id);
        setInitialValues({
            start_date: dayWorker(lesson.startDate).toDate(),
            end_date: dayWorker(lesson.endDate).toDate(),
            subject: lesson.subjectId,
            cur_group: lesson.curGroupId,
            audience: lesson.audience,
            teacher: lesson.teacherId,
        });
        setActiveLesson(column.index - 1);
    }

    function onRemoveSchedule() {
        setRemoveScheduleModal(true);
    }

    function onScheduleModalHide() {
        setRemoveScheduleModal(false);
    }

    function onRemoveScheduleSubmit(args: RemoveTimetableRangeArg) {
        deleteLessons(args)
            .unwrap()
            .then(() => {
                notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Вы успешно сняли расписание!',
                    },
                    true
                );
                onScheduleModalHide();
            });
    }

    return (
        <StudentLayout>
            <Toast ref={toast} />
            <Helmet title={'Расписание'} />
            {allowEdit ? (
                <>
                    <Modal
                        header={`${activeId ? 'Редактирование' : 'Создание'} расписания`}
                        visible={modal}
                        onHide={onHide}
                    >
                        <ModalContent
                            onSubmit={activeId ? onUpdate : onCreate}
                            initialValues={initialValues || {}}
                            onDelete={onDelete}
                            update={Boolean(activeId)}
                            deleteLoading={isDeleteLoading}
                            loading={
                                activeId ? isUpdateLoading : isCreateLoading
                            }
                        />
                    </Modal>
                    <Modal
                        header={`Удаление расписания`}
                        visible={removeScheduleModal}
                        onHide={onScheduleModalHide}
                    >
                        <RemoveScheduleModal
                            onSubmit={onRemoveScheduleSubmit}
                            loading={isDeleteRangeLoading}
                        />
                    </Modal>
                </>
            ) : null}
            <Card header={'Расписание занятий'} className={style.card}>
                <div className={style.timetable}>
                    <span className={style.header}>Календарь занятий</span>
                    <div
                        className={classNames(
                            style.wrapper,
                            'flex flex-wrap gap-4 mt-24'
                        )}
                    >
                        <div className={style.dates}>
                            <Button
                                onClick={prevWeek}
                                icon="pi pi-chevron-left white"
                                className="p-button-rounded p-button-outlined"
                            />
                            <Calendar
                                onChange={event => {
                                    if (event.value instanceof Date)
                                        setRange({
                                            start: dayWorker(
                                                event.value
                                            ).startOf('week'),
                                            end: dayWorker(event.value).endOf(
                                                'week'
                                            ),
                                        });
                                }}
                                dateTemplate={event => {
                                    const date = dayWorker(
                                        `${event.year}-${event.month + 1}-${event.day}`
                                    );

                                    const isBetween = date.isBetween(
                                        range.start,
                                        range.end,
                                        'days',
                                        '[]'
                                    );
                                    return (
                                        <div
                                            className={classNames({
                                                [style.active]: isBetween,
                                            })}
                                        >
                                            {event.day}
                                        </div>
                                    );
                                }}
                                readOnlyInput
                                value={range.start.toDate()}
                                dateFormat={`${range.start.format(
                                    'DD MMMM YYYY'
                                )} - ${range.end.format('DD MMMM YYYY')}`}
                            />
                            <Button
                                onClick={nextWeek}
                                icon="pi pi-chevron-right white"
                                className="p-button-rounded p-button-outlined"
                            />
                        </div>
                        {allowEdit ? (
                            <>
                                <Dropdown
                                    showClear
                                    value={group}
                                    options={map(
                                        selectData?.dataNameGroups,
                                        ({ curGroup, id }) => ({
                                            label: curGroup,
                                            value: id,
                                        })
                                    )}
                                    onChange={e => setGroup(e.value)}
                                    loading={isLoading}
                                    placeholder={'Группа'}
                                />
                                <Dropdown
                                    showClear
                                    value={audience}
                                    options={map(
                                        selectData?.audiences,
                                        ({ name, id }) => ({
                                            label: name,
                                            value: id,
                                        })
                                    )}
                                    onChange={e => setAudience(e.value)}
                                    loading={isLoading}
                                    placeholder={'Аудитория'}
                                />
                                <Dropdown
                                    showClear
                                    value={teacher}
                                    options={map(
                                        selectData?.teachers,
                                        ({ fio, id }) => {
                                            return {
                                                label: fio,
                                                value: id,
                                            };
                                        }
                                    )}
                                    onChange={e => setTeacher(e.value)}
                                    loading={isLoading}
                                    placeholder={'Преподаватель'}
                                />
                                <Button onClick={onRemoveSchedule}>
                                    Снять с занятий
                                </Button>
                            </>
                        ) : null}
                    </div>
                    <Table
                        allowEdit={allowEdit}
                        disabledTooltip={modal}
                        startDate={range.start}
                        loading={isFetching}
                        data={schedule}
                        onEdit={onEdit}
                        onAdd={onAdd}
                    />
                </div>
            </Card>
        </StudentLayout>
    );
};

import React, { useEffect, useRef, useState } from 'react'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { Card } from 'src/components/Card/Card'
import styles from './Homework.module.scss'
import { Dropdown } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'
import { HomeworkCard } from './components/HomeworkCard'
import useAppDispatch from '../../../hooks/useAppDispatch'
import {
    checkHomework,
    getHomeTaskDataTeacher,
    getHomeTaskFilterTeacher,
} from 'src/store/admin/homework/homework.actions'
import useAppSelector from '../../../hooks/useAppSelector'
import Loader from '../../../components/Loader'
import { map } from 'lodash'
import { Lesson } from 'src/components/Lesson/Lesson'
import { Helmet } from 'react-helmet'
import { Modal } from 'src/components/Modal/Modal'
import { Form, ModalContent } from './components/ModalContent'
import {
    HomeworkGroup,
    HomeworkHomework,
    HomeworkLesson,
    HomeworkSubject,
} from 'src/store/admin/homework/homework.types'
import { Toast } from 'primereact/toast'
import { NotificationTypes, notify } from 'src/utils/notify'

export const HomeworkPage = () => {
    const toast = useRef<Toast>(null)
    const dispatch = useAppDispatch()
    const filters = useAppSelector(state => state.adminHomeWorkSlice.filters)
    const students = useAppSelector(state => state.adminHomeWorkSlice.students)
    const [modal, setModal] = useState(false)
    const [group, setGroup] = useState<HomeworkGroup | null>(null)
    const [subject, setSubject] = useState<HomeworkSubject | null>(null)
    const [lesson, setLesson] = useState<HomeworkLesson | null>(null)
    const [homework, setHomework] = useState<HomeworkHomework | null>(null)
    const { pending: pendingFilterTeacher } =
        useAppSelector(
            state => state.adminHomeWorkSlice.getHomeTaskFilterTeacher,
        )
    const { pending: pendingDataTeacher } =
        useAppSelector(
            state => state.adminHomeWorkSlice.getHomeTaskDataTeacher,
        )
    useEffect(() => {
        dispatch(getHomeTaskFilterTeacher())
            .unwrap()
            .catch(e => {
                notify(toast, { type: NotificationTypes.error, content: e })
            })
    }, [])
    
    useEffect((): void => {
        setLesson(null)
    }, [subject])
    
    useEffect((): void => {
        getData()
    }, [lesson])
    
    const getData = (): void => {
        if (!lesson) return
        
        dispatch(getHomeTaskDataTeacher({ lessonId: lesson.lessonId }))
            .unwrap()
            .catch(e => {
                notify(toast, {
                    type: NotificationTypes.error,
                    content: e,
                })
            })
    }
    
    function onSubmit(form: Form): void {
        if (!homework || !lesson) return
        
        dispatch(
            checkHomework({
                mark: form.mark,
                desc: form.comment,
                homeWorkId: homework.homeWorkId,
                userId: homework.userId,
                subjectTheme: lesson.subjectTheme,
            }),
        )
            .unwrap()
            .then((): void => {
                notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Задание успешно проверено!',
                    },
                    true,
                )
                onHide()
            })
            .then((_: any): void => {
                getData()
            })
            .catch(e => {
                notify(toast, { type: NotificationTypes.error, content: e })
            })
    }
    
    function onHide() {
        setModal(false)
        setHomework(null)
    }
    
    return (
        <StudentLayout>
            <Toast ref={toast} />
            <Helmet title={'Домашнее задание'} />
            <Modal
                visible={modal}
                header={
                    homework?.status === 'Выполнено'
                    ? 'Просмотр домашнего задания'
                    : 'Проверка домашнего задания'
                }
                onHide={() => setModal(false)}
                className={styles.modal}
            >
                {homework ? (
                    <ModalContent homework={homework} onSubmit={onSubmit} />
                ) : null}
            </Modal>
            <Card
                className={styles.page}
                contentClassName={styles.pageContent}
                headerClassName={styles.pageHeader}
                header={'Домашнее задание'}
            >
                {pendingFilterTeacher ? <Loader /> : null}
                <div className={styles.header}>Выбор группы</div>
                <div className={'mt-24 flex flex-wrap gap-24'}>
                    <Dropdown
                        filter
                        value={group}
                        options={map(filters, filter => {
                            const { curGroup: groupName } = filter.group
                            return {
                                value: filter,
                                label: groupName,
                            }
                        })}
                        onChange={e => setGroup(e.value)}
                    />
                    {group ? (
                        <Dropdown
                            filter
                            value={subject}
                            options={map(
                                group.filtersTeacherSubjects,
                                subject => {
                                    const { subject: subjectName } = subject
                                    return {
                                        value: subject,
                                        label: subjectName,
                                    }
                                },
                            )}
                            onChange={e => setSubject(e.value)}
                        />
                    ) : null}
                </div>
                {subject ? (
                    <>
                        <div className={classNames('mt-48', styles.header)}>
                            Урок №
                        </div>
                        <div
                            className={'flex flex-wrap'}
                            style={{ gap: 12, marginTop: 12 }}
                        >
                            {map(
                                subject?.filtersTeacherLessons,
                                currentLesson => {
                                    const { lessonId, numberLesson, active } =
                                        currentLesson
                                    return (
                                        <Lesson
                                            key={lessonId}
                                            disabled={!active}
                                            active={
                                                lessonId === lesson?.lessonId
                                            }
                                            onClick={() =>
                                                setLesson(currentLesson)
                                            }
                                        >
                                            {numberLesson}
                                        </Lesson>
                                    )
                                },
                            )}
                        </div>
                    </>
                ) : null}
                {lesson ? (
                    <>
                        <div className={classNames('mt-48', styles.header)}>
                            Тема урока:
                        </div>
                        <div className={styles.class}>
                            {`Урок №${lesson.numberLesson}. ${lesson.subjectTheme}`}
                        </div>
                    </>
                ) : null}
                {lesson ? (
                    <>
                        <div className={classNames('mt-48', styles.header)}>
                            Студенты:
                        </div>
                        {pendingDataTeacher ? (
                            <Loader />
                        ) : (
                            <div className={'grid'}>
                                {map(students, homework => {
                                    const {
                                        status,
                                        mark,
                                        date,
                                        fioStudent,
                                        homeWorkId,
                                        avatarName,
                                        userId,
                                    } = homework
                                    return (
                                        <div
                                            key={userId}
                                            className={
                                                'col-12 md:col-6 lg:col-4'
                                            }
                                        >
                                            <HomeworkCard
                                                date={date}
                                                avatar={avatarName}
                                                fio={fioStudent}
                                                key={homeWorkId}
                                                status={status}
                                                mark={mark}
                                                statusText={status}
                                                onClick={(): void => {
                                                    setHomework(homework)
                                                    setModal(true)
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </>
                ) : null}
            </Card>
        </StudentLayout>
    )
}

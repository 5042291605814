import {
    AttendantMark,
    AttendantStudent,
    INearestLesson,
    Status,
} from 'src/store/admin/attendant/attendant.types';
import {
    AttendantGroup,
    AttendantSubject,
} from 'src/api/endpoints/attendant/attendant.types';
import { StudentLayout } from 'src/components/Layout/student/StudentLayout';
import { NearestLesson } from 'src/components/NearestLesson/NearestLesson';
import { ResponseWrapper } from 'src/api/endpoints/users/users.types.new';
import { useFinishLessonMutation } from 'src/api/endpoints/timetable';
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { ClassType } from 'src/components/ClassType/ClassType';
import { NotificationTypes, notify } from 'src/utils/notify';
import { ConfirmButton } from './components/ConfirmButton';
import { Form, ModalContent } from './components/Modal';
import useAppSelector from '../../hooks/useAppSelector';
import { Lesson } from 'src/components/Lesson/Lesson';
import useAppDispatch from 'src/hooks/useAppDispatch';
import { AttendantTable } from './components/Table';
import { Modal } from 'src/components/Modal/Modal';
import { FormItem, RowData } from './utils/utils';
import styles from './AttendantPage.module.scss';
import { dayWorker } from 'src/utils/dateWorker';
import { Card } from 'src/components/Card/Card';
import { Dropdown } from 'primereact/dropdown';
import { RootState } from 'src/store/store';
import Loader from '../../components/Loader';
import { Button } from 'primereact/button';
import { find, map, size } from 'lodash';
import { Toast } from 'primereact/toast';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import {
    getNearestDataLessonResponse,
    uploadMaterialAndHomework,
    presentsAttendantsType,
    getNearestDataLesson,
    savePresentUserData,
} from 'src/store/admin/attendant/attendant.actions';
import {
    useGetPresentsDataTeacherQuery,
    useGetPresentsFilterQuery,
    useIsFinishLessonQuery,
} from 'src/api/endpoints/attendant';
import { attendantSlice } from 'src/store/admin/attendant/attendant.slice';

interface AttendantPageProps {
    allowEdit?: boolean;
}

export interface EducationClassType {
    classType: number;
    lessonId: number;
}

export const AttendantPage: FC<AttendantPageProps> = ({
    allowEdit,
}): ReactElement => {
    const [data, setData] = useState<Array<RowData>>([]);
    const [classTypes, setClassTypes] = useState<EducationClassType[]>([]);
    const dispatch = useAppDispatch();
    const { data: filters, isLoading: areFiltersLoading } =
        useGetPresentsFilterQuery(undefined, {
            skip: allowEdit,
            refetchOnMountOrArgChange: true,
        });
    const { pending } = useAppSelector(
        (state: RootState) => state.adminAttendantSlice.getNearestDataLesson
    );
    const nearestLessons = useAppSelector(
        state => state.adminAttendantSlice.nearestLessons
    );
    const { removeNearestLesson, updatePresentStudentDate } =
        attendantSlice.actions;
    const [activeLesson, setActiveLesson] = useState<INearestLesson | null>(
        null
    );
    const [group, setGroup] = useState<AttendantGroup | null>(null);
    const [subject, setSubject] = useState<AttendantSubject | null>(null);
    const [lessonNumber, setLessonNumber] = useState<number | null>(null);
    const typeStatuses = useAppSelector(
        state => state.adminAttendantSlice.typeStatuses
    );
    const { data: presentsData, refetch } = useGetPresentsDataTeacherQuery(
        {
            lessonId: lessonNumber,
        },
        { skip: !lessonNumber, refetchOnMountOrArgChange: true }
    );

    const { data: isFinished, isLoading: isFinishedLoading } =
        useIsFinishLessonQuery(
            { lessonId: activeLesson?.lessons?.lessonId },
            {
                skip: !allowEdit || !activeLesson?.lessons?.lessonId,
                refetchOnMountOrArgChange: true,
            }
        );
    const [finish, { isLoading: isFinishLoading }] = useFinishLessonMutation();

    const typeMarks = useAppSelector(
        state => state.adminAttendantSlice.typeMarks
    );
    const toast = useRef<Toast>(null);
    const [modal, setModal] = useState(false);
    const currentLessonId = useRef(
        activeLesson?.lessons.lessonId ?? lessonNumber
    );

    function getData(setInitial: boolean = false): void {
        if (allowEdit) {
            dispatch(getNearestDataLesson())
                .unwrap()
                .then((response: getNearestDataLessonResponse | null): void => {
                    if (!response) return;

                    let lesson: INearestLesson | undefined;
                    if (setInitial && size(response.nearestLessons)) {
                        lesson = find(
                            response.nearestLessons,
                            (nearestLesson: INearestLesson): boolean =>
                                nearestLesson.lessons.active
                        );
                    } else {
                        if (!activeLesson) return;

                        lesson = find(
                            response.nearestLessons,
                            (nearestLesson: INearestLesson): boolean =>
                                nearestLesson.lessons.lessonId ===
                                activeLesson.lessons.lessonId
                        );
                    }

                    if (lesson) setActiveLesson(lesson);
                });
        }
    }

    useEffect((): void => {
        getData(true);
    }, []);

    useEffect((): void => {
        if (!activeLesson) return;

        setData(
            activeLesson?.academicDataLessonTeacher?.academicStudentModels || []
        );
    }, [activeLesson]);

    useEffect((): void => {
        setSubject(null);
        setLessonNumber(null);
    }, [group]);

    useEffect((): void => {
        setLessonNumber(null);
    }, [subject]);

    useEffect((): void => {
        if (!presentsData) return;

        setData(presentsData.academicStudentModels);
    }, [presentsData?.academicStudentModels]);

    const timer = useRef<NodeJS.Timeout>();
    useEffect((): (() => void) => {
        return (): void => {
            if (!timer.current) return;

            clearTimeout(timer.current);
        };
    }, []);

    function onRow(
        id: number,
        value: Partial<Pick<AttendantStudent, 'presentStudent'>>
    ): void {
        if (!activeLesson?.lessons.lessonId) return;

        dispatch(
            updatePresentStudentDate({
                lessonId: activeLesson.lessons.lessonId,
                studentId: id,
                data: value.presentStudent,
            })
        );

        setData(prevState =>
            map(prevState, row =>
                row.presentStudent.id === id
                    ? {
                          ...row,
                          presentStudent: {
                              ...row.presentStudent,
                              ...value.presentStudent,
                          },
                      }
                    : row
            )
        );
    }

    function onSingleUpdate(
        id: number,
        value: Partial<
            Pick<AttendantStudent, 'presentStudent'>['presentStudent']
        >
    ) {
        const localData = JSON.parse(JSON.stringify(data));
        const student = find(data, findStudent => {
            return findStudent.presentStudent.id === id;
        });
        const classType = classTypes.find(
            ct => ct.lessonId === currentLessonId.current
        );

        if (!student || !classType) {
            if (!classType && classType !== 0) {
                notify(
                    toast,
                    {
                        type: NotificationTypes.error,
                        content: 'Сначала необходимо выбрать тип урока!',
                    },
                    true
                );
            }

            return;
        }

        if (value.status === Status.absent) {
            value = {
                ...value,
                mark: 0,
                reward: 0,
                comment: null,
            };
        }

        onRow(id, {
            presentStudent: {
                ...student.presentStudent,
                ...value,
            },
        });

        if (!currentLessonId.current) {
            return notify(
                toast,
                {
                    type: NotificationTypes.error,
                    content: `FATAL ERROR: 'currentLessonId' is not number`,
                },
                true
            );
        }

        dispatch(
            savePresentUserData({
                ...student.presentStudent,
                ...value,
                typeMark: classType.classType,
                id: currentLessonId.current,
                userId: student.presentStudent.id,
            })
        )
            .unwrap()
            .then((_: presentsAttendantsType): void => {
                notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Информация о студентах успешно сохранена!',
                    },
                    true
                );
            })
            .catch((_reason: unknown): void => {
                setData(localData);
            });
    }

    function onSubmit(form: Form): void {
        if (!currentLessonId.current) {
            return notify(
                toast,
                {
                    type: NotificationTypes.error,
                    content: `FATAL ERROR: 'currentLessonId' is not number`,
                },
                true
            );
        }

        dispatch(
            uploadMaterialAndHomework({
                id: currentLessonId.current,
                materialId: form.material,
                ...(form.deadLine && {
                    deadline: form.deadLine.getTime(),
                }),
            })
        )
            .unwrap()
            .then((_): void => {
                setModal(false);
                allowEdit ? getData() : refetch();

                notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Методический материал успешно прикреплен!',
                    },
                    true
                );
            });
    }

    function onFinish(): void {
        if (!activeLesson) return;
        if (timer.current) clearTimeout(timer.current);

        finish({ lessonId: activeLesson.lessons.lessonId })
            .unwrap()
            .then((_wrapper: ResponseWrapper<null>): void => {
                dispatch(removeNearestLesson(activeLesson.lessons.lessonId));
                const nextLesson = nearestLessons.find(
                    lesson =>
                        lesson.lessons.lessonId !==
                            activeLesson.lessons.lessonId &&
                        lesson.lessons.active
                );

                if (nextLesson) {
                    setActiveLesson(nextLesson);
                } else {
                    setData([]);
                }

                notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Пара завершена!',
                    },
                    true
                );
            });
    }

    const updateClassTypes = (
        prevTypes: EducationClassType[],
        typeMark: AttendantMark
    ): EducationClassType[] => {
        if (!currentLessonId.current) {
            notify(
                toast,
                {
                    type: NotificationTypes.error,
                    content: `FATAL ERROR: 'currentLessonId' is not number`,
                },
                true
            );
            return [];
        }

        return prevTypes
            .filter(classType => classType.lessonId !== currentLessonId.current)
            .concat({
                classType: typeMark.typeId,
                lessonId: currentLessonId.current,
            });
    };

    useEffect(() => {
        if (activeLesson?.lessons.lessonId !== undefined) {
            currentLessonId.current = activeLesson?.lessons.lessonId;
        }
        if (lessonNumber !== null) {
            currentLessonId.current = lessonNumber;
        }
    }, [activeLesson, lessonNumber]);

    const packageModel =
        activeLesson?.academicDataLessonTeacher?.methodPackageModel ||
        presentsData?.methodPackageModel;

    return (
        <StudentLayout>
            <Toast ref={toast} />
            <Helmet title={'Присутствующие'} />
            <Card
                header={'Присутствующие'}
                className={'mt-24'}
                headerClassName={styles.header}
                contentClassName={'p-24'}
            >
                <Modal
                    header={'Методический материал'}
                    visible={modal}
                    onHide={() => setModal(false)}
                >
                    <ModalContent
                        onSubmit={onSubmit}
                        subjectId={subject?.subjectId}
                        initialValues={
                            packageModel
                                ? {
                                      deadLine: packageModel.deadline
                                          ? dayWorker(
                                                packageModel.deadline
                                            ).toDate()
                                          : new Date(),
                                      material: packageModel.material,
                                  }
                                : null
                        }
                    />
                </Modal>

                {!allowEdit ? (
                    filters ? (
                        <>
                            <FormItem label={'Выбор группы'}>
                                <div className={'flex gap-24'}>
                                    <Dropdown
                                        filter
                                        value={group}
                                        options={map(
                                            filters.filtersTeacherData
                                                .filtersTeacherGroupSubjects,
                                            subjects => {
                                                return {
                                                    value: subjects,
                                                    label: subjects.group
                                                        .curGroup,
                                                };
                                            }
                                        )}
                                        onChange={e => setGroup(e.value)}
                                    />
                                    {group ? (
                                        <Dropdown
                                            filter
                                            value={subject}
                                            options={map(
                                                group.filtersTeacherSubjects,
                                                subject => {
                                                    return {
                                                        value: subject,
                                                        label: subject.subject,
                                                    };
                                                }
                                            )}
                                            onChange={e => setSubject(e.value)}
                                        />
                                    ) : null}
                                </div>
                            </FormItem>
                            {subject ? (
                                <FormItem label={'Урок №'}>
                                    <div className={styles.classWrapper}>
                                        {size(subject.filtersTeacherLessons)
                                            ? map(
                                                  subject.filtersTeacherLessons,
                                                  ({
                                                      numberLesson,
                                                      lessonId,
                                                      active,
                                                  }) => (
                                                      <Lesson
                                                          key={lessonId}
                                                          disabled={!active}
                                                          active={
                                                              lessonId ===
                                                              lessonNumber
                                                          }
                                                          onClick={() =>
                                                              setLessonNumber(
                                                                  lessonId
                                                              )
                                                          }
                                                      >
                                                          {numberLesson}
                                                      </Lesson>
                                                  )
                                              )
                                            : 'Уроки отсутствуют'}
                                    </div>
                                </FormItem>
                            ) : null}
                        </>
                    ) : (
                        <Loader />
                    )
                ) : null}
                {size(nearestLessons) || lessonNumber ? (
                    pending ? (
                        <Loader />
                    ) : (
                        <>
                            {allowEdit && size(nearestLessons) ? (
                                <div className={styles.nearestLessonsSlider}>
                                    {map(nearestLessons, lesson => {
                                        const { lessons } = lesson;

                                        return (
                                            <div
                                                key={lessons.lessonId}
                                                className={classNames(
                                                    styles.nearestLesson,
                                                    {
                                                        [styles.many]:
                                                            nearestLessons.length >
                                                            2,
                                                    }
                                                )}
                                            >
                                                <NearestLesson
                                                    audience={
                                                        lessons.audienceName
                                                    }
                                                    activeLesson={
                                                        lessons.lessonId ===
                                                        currentLessonId.current
                                                    }
                                                    startDate={
                                                        lessons.startDate
                                                    }
                                                    endDate={lessons.endDate}
                                                    subject={lessons.subject}
                                                    group={lessons.group}
                                                    active={lessons.active}
                                                    onClick={() =>
                                                        setActiveLesson(lesson)
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null}
                            {activeLesson || lessonNumber ? (
                                <FormItem label={'Методический пакет'}>
                                    <Button
                                        disabled={isFinished === true}
                                        onClick={() => setModal(true)}
                                    >
                                        {activeLesson?.academicDataLessonTeacher
                                            ?.methodPackageModel ||
                                        presentsData?.methodPackageModel
                                            ? 'Обновить'
                                            : 'Загрузить'}
                                    </Button>
                                </FormItem>
                            ) : null}
                            {(activeLesson?.academicDataLessonTeacher
                                ?.methodPackageModel ||
                                presentsData?.methodPackageModel) &&
                            (activeLesson || lessonNumber) ? (
                                <FormItem label={'Тип урока'}>
                                    <div className={styles.classWrapper}>
                                        {map(
                                            allowEdit
                                                ? typeMarks
                                                : filters?.typeMarks,
                                            (typeMark: AttendantMark) => (
                                                <ClassType
                                                    disabled={isFinished}
                                                    key={typeMark.typeId}
                                                    active={
                                                        typeMark.typeId ===
                                                        classTypes.find(
                                                            ct =>
                                                                ct.lessonId ===
                                                                currentLessonId.current
                                                        )?.classType
                                                    }
                                                    onClick={() =>
                                                        setClassTypes(prev =>
                                                            updateClassTypes(
                                                                prev,
                                                                typeMark
                                                            )
                                                        )
                                                    }
                                                >
                                                    {typeMark.type}
                                                </ClassType>
                                            )
                                        )}
                                    </div>
                                </FormItem>
                            ) : null}
                            {data.length ? (
                                <>
                                    <AttendantTable
                                        statusLoading={
                                            allowEdit
                                                ? false
                                                : areFiltersLoading
                                        }
                                        disabled={isFinished}
                                        statusOptions={map(
                                            allowEdit
                                                ? typeStatuses
                                                : filters?.typeStatuses,
                                            ({ status, statusId }) => ({
                                                value: statusId,
                                                label: status,
                                            })
                                        )}
                                        data={data}
                                        classType={
                                            classTypes.find(
                                                ct =>
                                                    ct.lessonId ===
                                                    currentLessonId.current
                                            )!
                                        }
                                        onStatusChange={(event, rowData) =>
                                            onSingleUpdate(
                                                rowData.presentStudent.id,
                                                {
                                                    status: event.value,
                                                }
                                            )
                                        }
                                        onCommentChange={(value, rowData) =>
                                            onSingleUpdate(
                                                rowData.presentStudent.id,
                                                {
                                                    comment: value,
                                                }
                                            )
                                        }
                                        onRewardChange={(reward, rowData) =>
                                            onSingleUpdate(
                                                rowData.presentStudent.id,
                                                {
                                                    reward,
                                                }
                                            )
                                        }
                                        onMarkChange={(event, rowData) =>
                                            onSingleUpdate(
                                                rowData.presentStudent.id,
                                                {
                                                    mark: event.value,
                                                }
                                            )
                                        }
                                    />
                                    {allowEdit ? (
                                        <ConfirmButton
                                            loading={
                                                isFinishedLoading ||
                                                isFinishLoading
                                            }
                                            disabled={isFinished === true}
                                            onConfirm={onFinish}
                                        />
                                    ) : null}
                                </>
                            ) : null}
                        </>
                    )
                ) : allowEdit ? (
                    'Нет ближайших уроков'
                ) : null}
            </Card>
        </StudentLayout>
    );
};

import { baseApi, QueryPayload } from 'src/api'
import {
    AddStudentAccountArg,
    StudentResult,
    UpdateStudentAccountArg,
} from 'src/api/endpoints/students/students.types'

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getStudents: builder.query<Array<StudentResult>, void>({
            providesTags: ['Student'],
            query: (): QueryPayload => ({
                url: 'students',
                method: 'get',
                errorMessage: 'Не удалось получить информацию о студентах!',
            }),
            transformResponse(students: any[]) {
                return students.map((student: any) => ({
                    ...student,
                    role: student.role.id,
                    group: student.group.name,
                    groupId: student.group.id,
                }))
            },
        }),
        addStudentAccount: builder.mutation<void, AddStudentAccountArg>({
            invalidatesTags: ['Student'],
            query: (arg: AddStudentAccountArg): QueryPayload => ({
                url: 'students',
                method: 'post',
                data: {
                    login: arg.login,
                    password: arg.password,
                    groupId: arg.curGroup,
                    contract: arg.contract,
                },
                errorMessage: 'Не удалось добавить студента!',
            }),
        }),
        updateStudentAccount: builder.mutation<void, UpdateStudentAccountArg>({
            invalidatesTags: ['Student'],
            query: (arg: UpdateStudentAccountArg): QueryPayload => ({
                url: `students/${arg.studentId}`,
                method: 'PATCH',
                data: {
                    password: arg.password,
                    groupId: arg.curGroup,
                    contract: arg.contract,
                },
                errorMessage: 'Не удалось обновить студента!',
            }),
        }),
    }),
})

export const {
    useGetStudentsQuery,
    useAddStudentAccountMutation,
    useUpdateStudentAccountMutation,
} = api

import { StudentLayout } from '../../../components/Layout/student/StudentLayout';
import { Card } from '../../../components/Card/Card';
import { Column } from 'primereact/column';
import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';
import { requireField } from '../../../constants/constants';
import { Toast } from 'primereact/toast';
import { NotificationTypes, notify } from '../../../utils/notify';
import { Modal } from '../../../components/Modal/Modal';
import Loader from '../../../components/Loader';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';
import { Toolbar } from '../../../components/Toolbar';
import {
    useCreateAudienceMutation,
    useGetAllAudienceQuery,
    useRemoveAudienceMutation,
    useUpdateAudienceMutation,
} from '../../../api/endpoints/classroom';
import { FormItem } from '../../../components/Form';
import { Audience as IAudience } from '../../../api/endpoints/classroom/classroom.types';
import { DeleteButton } from 'src/components/DeleteButton';

export const Audience = () => {
    const [modal, setModal] = useState(false);
    const [initialState, setInitialState] = useState<IAudience | null>(null);
    const toast = useRef<Toast>(null);
    const { data, isLoading, isFetching, refetch } = useGetAllAudienceQuery();
    const [add, { isLoading: isAddLoading }] = useCreateAudienceMutation();
    const [update, { isLoading: isUpdateLoading }] =
        useUpdateAudienceMutation();
    const [remove] = useRemoveAudienceMutation();

    function onSubmit(form: Form) {
        if (initialState) {
            return update({
                path: {
                    audienceId: initialState.id,
                },
                body: { name: form.audienceName },
            })
                .unwrap()
                .then(() => {
                    notify(
                        toast,
                        {
                            type: NotificationTypes.success,
                            content: 'Вы успешно обновили группу!',
                        },
                        true
                    );
                    onHide();
                })
                .catch(e => {
                    notify(toast, {
                        type: NotificationTypes.error,
                        content: e,
                    });
                });
        }
        return add({
            body: {
                name: form.audienceName,
            },
        })
            .unwrap()
            .then(() => {
                notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Вы успешно создали группу!',
                    },
                    true
                );
            })
            .catch(e => {
                notify(toast, { type: NotificationTypes.error, content: e });
            });
    }

    const onDelete = (id: number) => {
        remove({ id })
            .unwrap()
            .then(() => {
                notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Вы успешно удалили аудиторию!',
                    },
                    true
                );
            })
            .then(() => refetch())
            .catch(e => {
                if (e.data) {
                    notify(toast, {
                        type: NotificationTypes.error,
                        content: e.data,
                    });
                }
            });
    };

    function onHide() {
        setModal(false);
        setInitialState(null);
    }
    return (
        <StudentLayout>
            <Helmet title={'Аудитории'} />
            <Toast ref={toast} />
            <Modal
                header={`${initialState ? 'Редактирование' : 'Создание'} аудитории`}
                visible={modal}
                onHide={onHide}
            >
                <ModalContent
                    initialState={
                        initialState
                            ? { audienceName: initialState.name }
                            : null
                    }
                    onSubmit={onSubmit}
                    loading={initialState ? isUpdateLoading : isAddLoading}
                />
            </Modal>
            <Card headerClassName={'mt-24'} header={'Аудитории'}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <DataTable
                        selectionMode={'single'}
                        onRowSelect={row => {
                            const rowData = row.data as IAudience;
                            setModal(true);
                            setInitialState(rowData);
                        }}
                        header={
                            <Toolbar
                                add={{
                                    onClick: () => setModal(true),
                                    loading: isAddLoading,
                                }}
                            />
                        }
                        loading={isFetching}
                        value={data?.data}
                    >
                        <Column sortable field={'name'} header="Название" />
                        <Column
                            body={column => {
                                return (
                                    <DeleteButton
                                        onClick={() => onDelete(column.id)}
                                    />
                                );
                            }}
                        />
                    </DataTable>
                )}
            </Card>
        </StudentLayout>
    );
};

interface Form {
    audienceName: string;
}

const ModalContent = ({
    initialState,
    onSubmit,
    loading,
}: {
    initialState: Form | null;
    onSubmit: (form: Form) => Promise<void>;
    loading: boolean;
}) => {
    const formik = useFormik({
        initialValues: initialState || {
            audienceName: '',
        },
        validate: ({ audienceName }) => {
            const errors: Partial<Record<keyof Form, any>> = {};
            if (!audienceName) errors.audienceName = requireField;
            return errors;
        },
        onSubmit: values => {
            onSubmit(values).then(() => formik.resetForm());
        },
    });
    const { audienceName } = formik.values;
    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid">
            <FormItem
                value={audienceName}
                name={'audienceName'}
                label={'Название аудитории'}
                formik={formik}
            >
                <InputText />
            </FormItem>
            <Button
                loading={loading}
                type="submit"
                label="Сохранить"
                className={'w-auto'}
            />
        </form>
    );
};

export enum StudentPaths {
    index = '/',
    progress = '/progress',
    materials = '/materials',
    homework = '/homework',
    mail = '/mail',
    timetable = '/timetable',
    payment = '/payment',
    profile = '/profile',
    notifications = '/notifications',
    practices = '/practices',
}

export enum TeacherPaths {
    index = '/',
    attendant = '/attendance',
    materials = '/materials',
    homework = '/homework',
    timetable = '/timetable',
    payment = '/payment',
    profile = '/profile',
    notifications = '/notifications',
}

export enum EduPaths {
    materials = '/materials',
    subjects = '/subjects',
    timetable = '/timetable',
    notifications = '/notifications',
    groups = '/groups',
    students = '/students',
    attendant = '/attendance',
    mail = '/mail',
    classrooms = '/classrooms',
    statistics = '/statistics',
    attendanceStatistic = '/attendanceStatistic',
    attendantLessonView = `/attendance/:lessonId/:groupId/:subjectId`,
    attendantGroupView = `/attendance/:groupId`,
    statisticsDetails = `/statistics/:id`,
    attendanceStatisticDetails = `/attendanceStatistic/:id`,
}

export enum DirectorFilialPaths {
    users = '/users',
    materials = '/materials',
    subjects = '/subjects',
    timetable = '/timetable',
    notifications = '/notifications',
    groups = '/groups',
    students = '/students',
    attendant = '/attendance',
    mail = '/mail',
    classrooms = '/classrooms',
}

export enum DirectorCompanyPaths {
    users = '/users',
    mail = '/mail',
}

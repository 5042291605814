import React, {
    FC,
    ReactNode,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import useAppSelector from '../../hooks/useAppSelector';
import config from '../../static/config.svg';
import cifra from '../../static/cifra.svg';
import avatarImg from '../../static/avatar.svg';
import notification from '../../static/notification.svg';
import './StudentHeader.scss';
import { IProps } from './StudentHeader.types';
import { classNames } from 'primereact/utils';
import { OverlayPanel } from 'primereact/overlaypanel';
import useAppDispatch from '../../hooks/useAppDispatch';
import { INotification, IUser } from 'src/store/user/user.types';
import { getStudentShortInfo } from 'src/store/student/info/info.actions';
import {
    getLastNotification,
    saveStatusNotification,
    saveStatusNotificationsList,
} from 'src/store/admin/notification/notification.actions';
import { map, size } from 'lodash';
import { Toast } from 'primereact/toast';
import { NotificationTypes, notify } from 'src/utils/notify';
import { TeacherPaths } from '../Routing/Routing.types';
import { Notification } from '../Notification/Notification';
import style from '../Notification/Notification.module.scss';
import { dayWorker } from 'src/utils';
import { dateFormatWithTime } from 'src/constants/constants';

export const StudentHeader: FC<IProps> = ({
    curGroup,
    coins,
    setIsVisible,
}) => {
    const [isMobile, setIsMobile] = useState(false);
    const { firstName, lastName, avatarURL, notifications, role } =
        useAppSelector(state => state.userReducer.user);
    const headerRef = useRef<HTMLHeadingElement>(null);

    useLayoutEffect(() => {
        function updateSize(): void {
            if (window.innerWidth < 450) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    function onMenu() {
        setIsVisible(prev => !prev);
    }

    return (
        <header ref={headerRef}>
            {role === 'student' ? (
                <StudentLayout
                    lastName={lastName}
                    notifications={notifications}
                    firstName={firstName}
                    avatarURL={avatarURL}
                    curGroup={curGroup}
                    isMobile={isMobile}
                    onMenu={onMenu}
                    coins={coins}
                />
            ) : (
                <TeacherLayout
                    lastName={lastName}
                    firstName={firstName}
                    isMobile={isMobile}
                    onMenu={onMenu}
                />
            )}
        </header>
    );
};

interface StudentLayoutProps
    extends Pick<IUser, 'lastName' | 'firstName' | 'notifications'> {
    avatarURL: string;
    curGroup: ReactNode;
    isMobile: boolean;
    onMenu: () => void;
    coins: ReactNode;
}

const StudentLayout = ({
    avatarURL,
    lastName,
    firstName,
    curGroup,
    isMobile,
    coins,
    onMenu,
}: StudentLayoutProps) => {
    const dispatch = useAppDispatch();

    const { hasShortLoaded } = useAppSelector(state => state.infoReducer);
    const toast = useRef<Toast>(null);
    useEffect(() => {
        if (!hasShortLoaded) {
            dispatch(getStudentShortInfo());
        }
    }, []);

    return (
        <>
            <Toast ref={toast} />
            <div className="flex align-items-center justify-content-center h-full">
                <Link
                    to="/profile/"
                    className="profile flex align-items-center"
                >
                    <div className="avatar">
                        <img
                            src={
                                avatarURL.endsWith('undefined') ||
                                avatarURL.endsWith('null')
                                    ? avatarImg
                                    : avatarURL
                            }
                            alt="logo"
                        />
                        <div className="config flex justify-content-center align-items-center">
                            <img src={config} alt="config" />
                        </div>
                    </div>
                    <div className="name">
                        <div className="last-name">{lastName}</div>
                        <div className="first-name">{firstName}</div>
                    </div>
                </Link>
                <div className="group w-full">{curGroup}</div>
                <div className="coins flex align-items-center cursor-pointer">
                    <Notifications
                        isMobile={isMobile}
                        onError={e =>
                            notify(toast, {
                                type: NotificationTypes.error,
                                content: e,
                            })
                        }
                    />
                    <div className="coin flex align-items-center">
                        <img src={cifra} alt="cifra" />
                        <div className="text">{coins}</div>
                    </div>
                </div>
                <div className="btn-container">
                    <Button
                        icon="pi pi-bars"
                        className="p-button-rounded p-button-outlined"
                        onClick={onMenu}
                    />
                </div>
            </div>
        </>
    );
};

interface TeacherProps extends Pick<IUser, 'lastName' | 'firstName'> {
    onMenu: () => void;
    isMobile: boolean;
}

const TeacherLayout = ({
    lastName,
    firstName,
    onMenu,
    isMobile,
}: TeacherProps) => {
    const toast = useRef<Toast>(null);
    return (
        <>
            <Toast ref={toast} />
            <div className="flex align-items-center justify-content-between h-full">
                <div className="profile flex align-items-center">
                    <div className="avatar">
                        <img src={avatarImg} alt="logo" />
                    </div>
                    <div className="name">
                        <div className="last-name">{lastName}</div>
                        <div className="first-name">{firstName}</div>
                    </div>
                </div>
                <div className="coins flex align-items-center cursor-pointer">
                    <Notifications
                        isMobile={isMobile}
                        onError={e =>
                            notify(toast, {
                                type: NotificationTypes.error,
                                content: e,
                            })
                        }
                    />
                </div>
                <div className="btn-container">
                    <Button
                        icon="pi pi-bars"
                        className="p-button-rounded p-button-outlined"
                        onClick={onMenu}
                    />
                </div>
            </div>
        </>
    );
};

const Notifications = ({
    isMobile,
    onError,
}: {
    isMobile: boolean;
    onError: (e: any) => void;
}) => {
    const notifications = useAppSelector(
        state => state.userReducer.user.notifications
    );
    const overlayPanelRef = useRef<OverlayPanel>(null);
    const dispatch = useAppDispatch();
    useEffect(() => {
        getData();
    }, []);

    function getData() {
        dispatch(getLastNotification()).unwrap().catch(onError);
    }

    function onCheck(notification: INotification) {
        dispatch(saveStatusNotification({ ...notification, isShow: true }))
            .unwrap()
            .catch(onError);
    }

    function onAllCheck() {
        dispatch(
            saveStatusNotificationsList(
                map(notifications.unread, notification => ({
                    ...notification,
                    isShow: true,
                }))
            )
        )
            .unwrap()
            .catch(onError);
    }
    const anyNotifications =
        size(notifications.unread) || size(notifications.read);
    return (
        <>
            <div
                className="coin flex align-items-center"
                onClick={e => overlayPanelRef.current?.toggle(e)}
                aria-haspopup
                aria-controls="overlay_panel"
            >
                <img src={notification} alt="notification" />
                <div className={classNames('text', { none: isMobile })}>
                    {size(notifications.unread)}
                </div>
            </div>
            <OverlayPanel ref={overlayPanelRef} id={'overlay_panel'}>
                <div className={style.notifications}>
                    {anyNotifications ? (
                        <>
                            <RenderNotifications
                                notifications={notifications.unread}
                                onCheck={onCheck}
                            />
                            <RenderNotifications
                                notifications={notifications.read}
                            />
                        </>
                    ) : (
                        <div className={'text-center'}>Нет уведомлений!</div>
                    )}
                </div>
                <div className={style.buttons}>
                    {size(notifications.unread) ? (
                        <Button
                            className="p-button-sm justify-content-center"
                            onClick={onAllCheck}
                        >
                            Прочитать все
                        </Button>
                    ) : null}
                    <Link to={TeacherPaths.notifications}>
                        <Button
                            label={'Все уведомления'}
                            className="p-button-sm justify-content-center w-full"
                        />
                    </Link>
                </div>
            </OverlayPanel>
        </>
    );
};

export function RenderNotifications({
    notifications,
    onCheck,
}: {
    notifications: Array<INotification>;
    onCheck?: (notification: INotification) => void;
}) {
    return (
        <>
            <div className={style.notificationGroup}>
                {onCheck ? 'Непрочитанные' : 'Прочитанные'}:
            </div>
            {size(notifications) ? (
                map(notifications, (notification, index) => {
                    const { id, title, message, createdAt, additional } =
                        notification;
                    return (
                        <Notification
                            striped={index % 2 !== 0}
                            key={id}
                            title={title}
                            date={dayWorker(createdAt).format(
                                dateFormatWithTime
                            )}
                            body={message}
                            onCheck={
                                onCheck
                                    ? () => onCheck(notification)
                                    : undefined
                            }
                            additional={additional}
                        />
                    );
                })
            ) : (
                <div className={'p-24'}>{`${
                    onCheck ? 'Непрочитанных' : 'Прочитанных'
                } уведомлений нет`}</div>
            )}
        </>
    );
}

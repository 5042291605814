import { cloneElement, ReactElement, ReactNode } from 'react'
import { classNames } from 'primereact/utils'
import { FormikProps } from 'formik'

interface FormItem<T> {
    formik: FormikProps<T>;
    name: keyof T;
    label: ReactNode;
    children: ReactElement;
    value: any;
}

export const FormItem = <Form, >({
                                     formik,
                                     name,
                                     value,
                                     label,
                                     children,
                                 }: FormItem<Form>) => {
    const isFormFieldValid = (name: keyof Form) =>
        !!(formik.touched[name] && formik.errors[name])
    const getFormErrorMessage = (name: keyof Form) => {
        if (formik.errors && Object.hasOwn(formik.errors, name)) {
            return (
                isFormFieldValid(name) && (
                    <small className="p-error">
                        {formik.errors[name]?.toString()}
                    </small>
                )
            )
        }
    }
    
    return (
        <div className="field">
            <span className="p-float-label">
                {cloneElement(children, {
                    onChange: formik.handleChange,
                    id: name,
                    value,
                    name,
                    className: classNames(children?.props?.className, {
                        'p-invalid': isFormFieldValid(name),
                    }),
                })}
                <label
                    htmlFor={name.toString()}
                    className={classNames({
                        'p-error': isFormFieldValid(name),
                    })}
                >
                    {label}
                </label>
            </span>
            {getFormErrorMessage(name)}
        </div>
    )
}

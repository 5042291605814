import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { useAddGroupMutation, useGetGroupsQuery } from 'src/api'
import { EduPaths } from 'src/components/Routing/Routing.types'
import { NotificationTypes, notify } from 'src/utils/notify'
import { requireField } from 'src/constants/constants'
import React, { FC, useRef, useState } from 'react';
import { DataTable } from 'src/components/DataTable'
import { Modal } from 'src/components/Modal/Modal'
import { InputText } from 'primereact/inputtext'
import { Toolbar } from 'src/components/Toolbar'
import { Card } from 'src/components/Card/Card'
import { classNames } from 'primereact/utils'
import Loader from 'src/components/Loader'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';

export const Groups: FC = () => {
    const [modal, setModal] = useState(false);
    const toast = useRef<Toast>(null);
    const { data, isLoading, isFetching } = useGetGroupsQuery();
    const [add, { isLoading: isAddLoading }] = useAddGroupMutation();

    function onSubmit({ groupName }: Form) {
        return add({
            name: groupName,
        })
            .unwrap()
            .then(() => {
                notify(
                    toast,
                    {
                        type: NotificationTypes.success,
                        content: 'Вы успешно создали группу!',
                    },
                    true
                );
            })
            .catch(e => {
                notify(toast, { type: NotificationTypes.error, content: e });
            });
    }

    return (
        <StudentLayout>
            <Helmet title={'Группы'} />
            <Toast ref={toast} />
            <Modal
                header={'Создание Группы'}
                visible={modal}
                onHide={() => setModal(false)}
            >
                <ModalContent onSubmit={onSubmit} loading={isAddLoading} />
            </Modal>
            <Card headerClassName={'mt-24'} header={'Группы'}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <DataTable
                        header={
                            <Toolbar
                                add={{
                                    onClick: () => setModal(true),
                                    loading: isAddLoading,
                                }}
                            />
                        }
                        loading={isFetching}
                        value={data}
                    >
                        <Column
                            sortable
                            field={'curGroup'}
                            body={rowData => (
                                <Link
                                    to={`${EduPaths.attendant}/${rowData.id}`}
                                >
                                    {rowData.curGroup}
                                </Link>
                            )}
                            header="Название"
                        />
                    </DataTable>
                )}
            </Card>
        </StudentLayout>
    );
};

interface Form {
    groupName: string;
}

const ModalContent = ({
    onSubmit,
    loading,
}: {
    onSubmit: (form: Form) => Promise<void>;
    loading: boolean;
}) => {
    const formik = useFormik({
        initialValues: {
            groupName: '',
        },
        validate: ({ groupName }) => {
            const errors: Partial<Record<keyof Form, any>> = {}
            if (!groupName) errors.groupName = requireField;
            return errors;
        },
        onSubmit: values => {
            onSubmit(values).then(() => formik.resetForm());
        },
    });

    const isFormFieldValid = (name: keyof Form) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: keyof Form) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };
    const { groupName } = formik.values;
    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="field">
                <span className="p-float-label">
                    <InputText
                        id="groupName"
                        name="groupName"
                        value={groupName}
                        onChange={formik.handleChange}
                        className={classNames({
                            'p-invalid': isFormFieldValid('groupName'),
                        })}
                    />
                    <label
                        htmlFor="groupName"
                        className={classNames({
                            'p-error': isFormFieldValid('groupName'),
                        })}
                    >
                        Название группы
                    </label>
                </span>
                {getFormErrorMessage('groupName')}
            </div>
            <Button
                loading={loading}
                type="submit"
                label="Сохранить"
                className={'w-auto'}
            />
        </form>
    );
};

import { getTimetable } from 'src/store/student/timetable/timetable.actions'
import { StudentLayout } from 'src/components/Layout/student/StudentLayout'
import { FC, ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { getLastDayOfMonth } from 'src/utils/date/getLastDayOfMonth'
import { capitalizeString } from 'src/utils/date/capitalizeString'
import { ProgressSpinner } from 'primereact/progressspinner'
import { parseIOSDate } from 'src/utils/date/parseIOSDate'
import { getDateMonth } from 'src/utils/date/getDateMonth'
import useErrorHandler from 'src/hooks/useErrorHandler'
import { formatDate } from 'src/utils/date/formatDate'
import useAppSelector from 'src/hooks/useAppSelector'
import useAppDispatch from 'src/hooks/useAppDispatch'
import { getTime } from 'src/utils/date/getTime'
import { classNames } from 'primereact/utils'
import { IWeek } from './TimetablePage.types'
import { Button } from 'primereact/button'
import Loader from 'src/components/Loader'
import { dayWorker } from 'src/utils'
import './TimetablePage.scss'

export const TimetablePage: FC = (): ReactElement => {
    // state and ref
    const [filter, setFilter] = useState({
        month: new Date().getMonth(),
        monthText: new Date().toLocaleDateString('ru-RU', { month: 'long' }),
        year: new Date().getFullYear(),
    })
    
    const [timetable, setTimetable] = useState<IWeek[]>([])
    const [timetableContainerWidth, setTimetableContainerWidth] = useState<number>(1)
    const contentRef = useRef<HTMLDivElement>(null)
    
    // hooks
    const { isLoading, hasLoaded, data } = useAppSelector(
        state => state.timetableReducer,
    )
    const dispatch = useAppDispatch()
    const errorHandler = useErrorHandler()
    
    useEffect((): void => {
        document.title = 'Расписание занятий'
        
        if (!hasLoaded) {
            dispatch(
                getTimetable({
                    month: filter.month + 1,
                    year: filter.year,
                }),
            )
                .unwrap()
                .catch(error => errorHandler(error))
        }
    }, [])
    
    useEffect((): void => {
        if (!data.lessons.length)
            return setTimetable([])
        
        
        const tt: IWeek[] = [
            {
                active: false,
                lessons: [],
            },
        ]
        let week = 0
        
        for (
            // ! it's necessary
            // eslint-disable-next-line no-var
            var i = 1;
            i <= getLastDayOfMonth(filter.year, filter.month);
            i++
        ) {
            const date = new Date(filter.year, filter.month, i)
            
            if (i === 1) {
                let weekday = date.getDay()
                
                if (weekday === 0) {
                    weekday = 7
                }
                
                let day
                
                if (filter.month === 0) {
                    day = 31 - weekday + 2
                } else {
                    day =
                        getLastDayOfMonth(filter.year, filter.month - 1) -
                        weekday +
                        2
                }
                
                while (weekday !== 1) {
                    tt[week].lessons.push({
                        date: new Date(filter.year, filter.month - 1, day),
                        enabled: false,
                    })
                    
                    weekday--
                    day++
                }
            }
            
            const lessons = data.lessons.filter(l => {
                if (
                    parseIOSDate(l.startDate).getDate() === date.getDate()
                ) {
                    return l
                }
            })
            
            if (lessons.length) {
                tt[week].lessons.push({
                    date,
                    enabled: true,
                    lessons,
                })
            } else {
                tt[week].lessons.push({
                    date,
                    enabled: true,
                })
            }
            
            if (tt[week].lessons.length === 7) {
                tt.push({
                    lessons: [],
                    active: false,
                })
                week++
            }
        }
        
        while (tt[week].lessons.length !== 7) {
            const date: Date = new Date(filter.year, filter.month, i)
            
            tt[week].lessons.push({
                date,
                enabled: false,
            })
            
            i++
        }
        
        setTimetable(tt)
    }, [data.lessons])
    
    useLayoutEffect(() => {
        function updateSize(): void {
            setTimetableContainerWidth(
                contentRef.current!.getBoundingClientRect().width - 48,
            )
        }
        
        window.addEventListener('resize', updateSize)
        updateSize()
        return () => window.removeEventListener('resize', updateSize)
    }, [])
    
    useEffect((): void => {
        if (window.innerWidth <= 1100 && contentRef.current) {
            setTimetableContainerWidth(
                contentRef.current!.getBoundingClientRect().width - 48,
            )
        }
    }, [contentRef.current])
    
    const increaseMonth = (): void => {
        let year: number
        let month: number
        let monthText: string
        
        if (filter.month === 11) {
            month = 0
            year = filter.year + 1
            monthText = 'Январь'
        } else {
            month = filter.month + 1
            year = filter.year
            monthText = new Date(
                filter.year,
                filter.month + 1,
                1,
            ).toLocaleDateString('ru-RU', { month: 'long' })
        }
        
        setFilter({
            month,
            year,
            monthText,
        })
        
        dispatch(
            getTimetable({
                month: month + 1,
                year: year,
            }),
        )
    }
    
    const decreaseMonth = (): void => {
        let year: number
        let month: number
        let monthText: string
        
        if (filter.month === 0) {
            month = 11
            year = filter.year - 1
            monthText = 'Декабрь'
        } else {
            month = filter.month - 1
            year = filter.year
            monthText = new Date(
                filter.year,
                filter.month - 1,
                1,
            ).toLocaleDateString('ru-RU', { month: 'long' })
        }
        
        setFilter({
            month,
            year,
            monthText,
        })
        
        dispatch(
            getTimetable({
                month: month + 1,
                year: year,
            }),
        )
    }
    
    return (
        <StudentLayout pageName="timetable-page">
            <div className="card mt-24">
                <div className="header flex align-items-center justify-content-between timetable-bg">
                    <div className="title big">Расписание занятий</div>
                </div>
                {isLoading && !hasLoaded ? (
                    <Loader />
                ) : (
                    <div className="content" ref={contentRef}>
                        {data.nearestLessons.length ? (
                            <>
                                <div className="text fz-20 bold">
                                    Ближайшие занятия
                                </div>
                                <div className="flex mt-24 justify-content-between overflow-x-scroll nearest-lessons">
                                    <div className="col-6 py-0 lesson pr-05 pr-2">
                                        <div
                                            className="area bg timetable-bg flex flex-column justify-content-between h-full">
                                            <div className="flex justify-content-between title">
                                                <div className="fz-24 text-white">
                                                    {parseIOSDate(
                                                        data.nearestLessons[0]
                                                            .startDate,
                                                    ).getDate()}{' '}
                                                    {getDateMonth(
                                                        data.nearestLessons[0]
                                                            .startDate,
                                                    )}
                                                </div>
                                                <div className="delimiter"></div>
                                                <div className="fz-24 text-white">
                                                    {getTime(
                                                        data.nearestLessons[0]
                                                            .startDate,
                                                    )}
                                                    -
                                                    {getTime(
                                                        data.nearestLessons[0]
                                                            .endDate,
                                                    )}
                                                </div>
                                            </div>
                                            <small className="date mt-24">
                                                Предмет
                                            </small>
                                            <div className="fz-20 text-white mt-4">
                                                {data.nearestLessons[0].subject}
                                            </div>
                                            <small className="date mt-24">
                                                Аудитория
                                            </small>
                                            <div className="fz-20 text-white mt-4">
                                                {
                                                    data.nearestLessons[0]
                                                        .audienceName
                                                }
                                            </div>
                                            <small className="date mt-24">
                                                Преподаватель
                                            </small>
                                            <div className="fz-20 text-white mt-4">
                                                {data.nearestLessons[0].teacher}
                                            </div>
                                        </div>
                                    </div>
                                    {data.nearestLessons.length > 1 ? (
                                        <div className="col-6 py-0 lesson pr-05 second pl-2">
                                            <div
                                                className="area bg timetable-bg flex flex-column justify-content-between h-full">
                                                <div className="flex justify-content-between title">
                                                    <div className="fz-24 text-white">
                                                        {parseIOSDate(
                                                            data
                                                                .nearestLessons[1]
                                                                .startDate,
                                                        ).getDate()}{' '}
                                                        {getDateMonth(
                                                            data
                                                                .nearestLessons[1]
                                                                .startDate,
                                                        )}
                                                    </div>
                                                    <div className="delimiter"></div>
                                                    <div className="fz-24 text-white">
                                                        {getTime(
                                                            data
                                                                .nearestLessons[1]
                                                                .startDate,
                                                        )}
                                                        -
                                                        {getTime(
                                                            data
                                                                .nearestLessons[1]
                                                                .endDate,
                                                        )}
                                                    </div>
                                                </div>
                                                <small className="date mt-24">
                                                    Предмет
                                                </small>
                                                <div className="fz-20 text-white mt-4">
                                                    {
                                                        data.nearestLessons[1]
                                                            .subject
                                                    }
                                                </div>
                                                <small className="date mt-24">
                                                    Тема
                                                </small>
                                                <div className="fz-20 text-white mt-4">
                                                    {
                                                        data.nearestLessons[0]
                                                            .subjectTheme
                                                    }
                                                </div>
                                                <small className="date mt-24">
                                                    Преподаватель
                                                </small>
                                                <div className="fz-20 text-white mt-4">
                                                    {
                                                        data.nearestLessons[1]
                                                            .teacher
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </>
                        ) : null}
                        <div className="flex filter mt-48">
                            <div className="date">
                                {capitalizeString(filter.monthText)}{' '}
                                {filter.year}
                            </div>
                            <div className="flex ml-auto buttons">
                                <Button
                                    icon="pi pi-chevron-left"
                                    className="p-button-rounded"
                                    onClick={decreaseMonth}
                                />
                                <Button
                                    icon="pi pi-chevron-right"
                                    className="p-button-rounded ml-12"
                                    onClick={increaseMonth}
                                />
                            </div>
                        </div>
                        <div
                            className="mt-24 calendar overflow-x-scroll"
                            style={{
                                width: timetableContainerWidth,
                            }}
                        >
                            {isLoading ? (
                                <div className="w-full flex align-content-center justify-content-center">
                                    <ProgressSpinner />
                                </div>
                            ) : timetable.length ? (
                                timetable.map((week, weekIDX) => (
                                    <div
                                        className="flex justify-content-between"
                                        key={weekIDX}
                                    >
                                        {week.lessons.map((day, idx) => {
                                            return (
                                                <div
                                                    className={
                                                        'calendar-item ' +
                                                        (idx % 7 === 0
                                                         ? 'pl-0'
                                                         : '')
                                                    }
                                                    key={day.date.toDateString()}
                                                    onClick={() => {
                                                        if (day.lessons) {
                                                            setTimetable(prev =>
                                                                prev.map(
                                                                    (
                                                                        w,
                                                                        idx,
                                                                    ) => {
                                                                        if (
                                                                            idx ===
                                                                            weekIDX
                                                                        ) {
                                                                            w.active =
                                                                                !w.active
                                                                        }
                                                                        
                                                                        return w
                                                                    },
                                                                ),
                                                            )
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className={classNames(
                                                            'calendar-item__content',
                                                            {
                                                                active: day.lessons,
                                                                disabled:
                                                                    !day.enabled,
                                                                current:
                                                                    dayWorker(
                                                                        day.date,
                                                                    ).format(
                                                                        'MM-DD',
                                                                    ) ===
                                                                    dayWorker().format(
                                                                        'MM-DD',
                                                                    ) &&
                                                                    !day.lessons,
                                                            },
                                                        )}
                                                    >
                                                        <div
                                                            className="head flex justify-content-between align-items-center m-24">
                                                            <h1>
                                                                {formatDate(
                                                                    day.date,
                                                                )}
                                                            </h1>
                                                            <div className="day">
                                                                {day.date
                                                                    .toLocaleDateString(
                                                                        'ru-RU',
                                                                        {
                                                                            weekday:
                                                                                'short',
                                                                        },
                                                                    )
                                                                    .toUpperCase()}
                                                            </div>
                                                        </div>
                                                        {week.active ? (
                                                            <div className="body">
                                                                {day.lessons?.map(
                                                                    l => (
                                                                        <div
                                                                            className="lesson"
                                                                            key={
                                                                                l.startDate +
                                                                                l.subject
                                                                            }
                                                                        >
                                                                            <div className="bold">
                                                                                {
                                                                                    l.subject
                                                                                }
                                                                            </div>
                                                                            <div className="mt-6">
                                                                                {
                                                                                    l.subjectTheme
                                                                                }
                                                                            </div>
                                                                            <div className="mt-12">
                                                                                {
                                                                                    l.teacher
                                                                                }
                                                                            </div>
                                                                            <div className="mt-6">
                                                                                {
                                                                                    l?.audienceName
                                                                                }
                                                                            </div>
                                                                            <div className="mt-6">
                                                                                {getTime(
                                                                                    l.startDate,
                                                                                )}
                                                                                
                                                                                -
                                                                                {getTime(
                                                                                    l.endDate,
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ),
                                                                )}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                ))
                            ) : (
                                                                             <div className="fz-20">
                                                                                 В этом месяце занятий нет
                                                                             </div>
                                                                         )}
                        </div>
                    </div>
                )}
            </div>
        </StudentLayout>
    )
}

import { ResponseWrapper } from 'src/api/endpoints/users/users.types.new';
import { baseApi, QueryPayload } from 'src/api';
import {
    Audience,
    CreatingAudienceRequestPayload,
    UpdatingAudienceRequestPayload,
} from 'src/api/endpoints/classroom/classroom.types';

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        updateAudience: builder.mutation<
            ResponseWrapper<null>,
            UpdatingAudienceRequestPayload
        >({
            invalidatesTags: ['Audience'],
            query: (payload: UpdatingAudienceRequestPayload): QueryPayload => ({
                url: `audiences/${payload.path.audienceId}`,
                method: 'PATCH',
                data: payload.body,
                errorMessage: 'Не удалось обновить аудиторию!',
            }),
        }),
        createAudience: builder.mutation<
            ResponseWrapper<null>,
            CreatingAudienceRequestPayload
        >({
            invalidatesTags: ['Audience'],
            query: (payload: CreatingAudienceRequestPayload): QueryPayload => ({
                url: 'audiences',
                method: 'POST',
                data: payload.body,
                errorMessage: 'Не удалось создать аудиторию!',
            }),
        }),
        getAllAudience: builder.query<ResponseWrapper<Audience[]>, void>({
            providesTags: ['Audience'],
            query: (): QueryPayload => ({
                url: 'audiences',
                method: 'get',
                errorMessage: 'Не удалось получить информацию об аудиториях!',
            }),
            transformResponse(val: any) {
                return {
                    statusCode: 200,
                    message: '',
                    data: val,
                };
            },
        }),
        removeAudience: builder.mutation<ResponseWrapper<null>, { id: number }>(
            {
                invalidatesTags: ['Audience'],
                query: (payload): QueryPayload => ({
                    url: `audiences/${payload.id}`,
                    method: 'DELETE',
                    errorMessage:
                        'Не удалось удалить аудиторию из-за существующих зависимостей!',
                }),
            }
        ),
    }),
});

export const {
    useCreateAudienceMutation,
    useUpdateAudienceMutation,
    useGetAllAudienceQuery,
    useRemoveAudienceMutation,
} = api;

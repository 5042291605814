import { baseApi } from 'src/api';
import {
    getNearestDataLessonResult,
    getPerformanceGroupArg,
    getPerformanceGroupResult,
    getPresentsFilterResult,
    getSubjectNameByGroupArg,
    getSubjectNameByGroupResult,
    isFinishLessonArg,
} from './attendant.types';

const api = baseApi.injectEndpoints({
    endpoints: builder => ({
        getPresentsFilter: builder.query<getPresentsFilterResult, void>({
            query: () => ({
                url: 'performance/getPresentsFilterTeacher',
                method: 'GET',
                errorMessage: 'Не удалось получить информацию о фильтрах!',
            }),
            transformResponse(val: any) {
                return {
                    typeMarks: val.marks.map(el => ({
                        typeId: el.id,
                        type: el.name,
                    })),
                    typeStatuses: val.statuses.map(el => ({
                        statusId: el.id,
                        status: el.name,
                    })),
                    filtersTeacherData: {
                        filtersTeacherGroupSubjects: val.groups.map(group => {
                            return {
                                group: {
                                    curGroup: group.group.name,
                                    id: group.group.id,
                                },
                                filtersTeacherSubjects: group.subjects.map(
                                    subject => ({
                                        subject: subject.subject.name,
                                        subjectId: subject.subject.id,
                                        filtersTeacherLessons:
                                            subject.lessons.map(lesson => ({
                                                numberLesson:
                                                    lesson.numberLesson,
                                                lessonId: lesson.id,
                                                subjectTheme: lesson.theme,
                                                active: lesson.active,
                                            })),
                                    })
                                ),
                            };
                        }),
                    },
                };
            },
        }),
        getPresentsDataTeacher: builder.query<
            getNearestDataLessonResult,
            { lessonId: number | null }
        >({
            query: params => ({
                url: 'performance/getPresentsDataTeacher',
                method: 'get',
                params,
                errorMessage:
                    'Не удалось получить информацию о присутствующих!',
            }),
            transformResponse(value: any) {
                const { material } = value;
                return {
                    academicStudentModels: value.attendance.map(
                        ({ attendance, fullname }) => ({
                            fio: fullname,
                            presentStudent: {
                                id: attendance.id,
                                userId: attendance.userId,
                                status: attendance.status,
                                mark: attendance.mark,
                                reward: attendance.reward,
                                comment: attendance.comment,
                                typeMark: attendance.markType,
                                presentLessonId: attendance.presentLessonId,
                                theme: null,
                            },
                        })
                    ),
                    methodPackageModel: material
                        ? {
                              deadline: material.deadline,
                              material: {
                                  id: material.material.id,
                                  createdDate: material.material.createdAt,
                                  theme: material.material.theme,
                                  name: material.material.name,
                                  source: material.material.source,
                                  pathFileMaterial: null,
                                  pathHomeworkFile: null,
                                  pathPracticeFile: null,
                                  pathPresentationFile: null,
                              },
                          }
                        : null,
                };
            },
        }),
        getSubjectNameByGroup: builder.query<
            getSubjectNameByGroupResult,
            getSubjectNameByGroupArg
        >({
            query: params => ({
                url: 'performance/getSubjectNameByGroup',
                method: 'get',
                params,
                errorMessage: 'Не удалось получить информацию о предметах!',
            }),
        }),
        getPerformanceGroup: builder.query<
            getPerformanceGroupResult,
            getPerformanceGroupArg
        >({
            query: params => ({
                url: 'performance/getPerformanceGroup',
                method: 'get',
                params,
                errorMessage:
                    'Не удалось получить информацию о присутствующих!',
            }),
        }),
        isFinishLesson: builder.query<boolean, isFinishLessonArg>({
            providesTags: ['LessonFinished'],
            query: params => ({
                url: 'performance/isFinishLesson',
                method: 'get',
                params,
                errorMessage:
                    'Не удалось получить информацию о присутствующих!',
            }),
        }),
    }),
});

export const {
    useGetPresentsFilterQuery,
    useGetPresentsDataTeacherQuery,
    useGetPerformanceGroupQuery,
    useGetSubjectNameByGroupQuery,
    useIsFinishLessonQuery,
} = api;
